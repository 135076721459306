// Imports
import React, { useState, useEffect, useRef } from 'react';

// Components
import Page from '../../../elements/Page';
import Section from '../../../elements/Section';
import { Skeleton, Flex, Heading, Text, Badge } from '@radix-ui/themes'
import ContentInput from '../../../inputs/ContentInput';
import IconButton from '../../../buttons/IconButton';
import ContentViewer from '../../../elements/ContentViewer';
import AddTeamMember from '../../../sections/manageTeamPage/AddTeamMember';
import EditTeamMember from '../../../sections/manageTeamPage/EditTeamMember';
import SelectInput from '../../../inputs/SelectInput';
import Alert from '../../../feedback/Alert';

// Utils
import api from '@vikingevents/viking-fe-util-api';
import spinner from '@vikingevents/viking-fe-util-spinner';
import session from '@vikingevents/viking-fe-util-session';
import defaults from '../../../../utils/defaults';
import notification from '@vikingevents/viking-fe-util-notification';
import assets from '@vikingevents/viking-fe-util-assets';

// Icons
import { Pencil2Icon, TrashIcon } from '@radix-ui/react-icons';

// SocialAnalyticsPage component
const SocialAnalyticsPage = () => {

	// Return component
	return (

		<Page 
			title="Marketing - Social Analytics"
		>

			<Section
				title="Marketing - Social Analytics"
			>

			</Section>

		</Page>

    )

};

// Export Component
export default SocialAnalyticsPage;
