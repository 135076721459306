// Imports
import React from 'react';

// Components
import Page from '../../elements/Page';
import Section from '../../elements/Section';

// PageNotFoundPage component
const PageNotFoundPage = () => {

	// Return component
	return (

		<Page
			title="404 - Page Not Found"
		>

			<Section>

				<h1>Page Not Found Page</h1>

			</Section>

		</Page>

    )

};

// Export Component
export default PageNotFoundPage;
