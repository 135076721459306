// Store Consts
const SESSION_KEY = 'viking_vms_session';
const CACHE_KEY = 'viking_vms_cache';
const CACHEABLE_ENDPOINTS = [
    {
        endpoint: "/marketing/media",
        type: "memCache"
    },
    {
        endpoint: "/marketing/gallery",
        type: "memCache"
    },
    {
        endpoint: "/marketing/news",
        type: "memCache"
    },
    {
        endpoint: "/marketing/team",
        type: "memCache"
    },
    {
        endpoint: "/marketing/recent-projects",
        type: "memCache"
    },
    {
        endpoint: "/permissions",
        type: "memCache"
    },
    {
        endpoint: "/users",
        type: "memCache"
    }
];

export default {
    consts: {
        SESSION_KEY: SESSION_KEY,
        CACHE_KEY: CACHE_KEY,
        CACHEABLE_ENDPOINTS: CACHEABLE_ENDPOINTS
    }
}