// Imports
import React, { useState } from 'react';
import PropTypes from 'prop-types';
//import { Input, InputRightElement, InputGroup, Button } from '@chakra-ui/react'
//import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { TextField } from '@radix-ui/themes'
import { EyeOpenIcon, EyeNoneIcon } from '@radix-ui/react-icons'

// PasswordInput component
const PasswordInput = React.forwardRef((props, ref) => {

    // State
    const [showPassword, setShowPassword] = useState(false);

	// Return component
	return (

        <TextField.Root 
            placeholder={props.placeholder}
            type={showPassword ? 'text' : 'password'}
            ref={ref}
            mt="10px"
            mb="20px"
            fontSize="14px"
            disabled={props.disabled}
            onChange={props.onChange}
            color="orange"
        >
            <TextField.Slot className="cursor-pointer"> 
                {
                    showPassword ? 
                        <EyeOpenIcon 
                            height="16"
                            width="16"
                            onClick={() => { setShowPassword(false); }}
                        /> 
                    : 
                        <EyeNoneIcon 
                            height="16"
                            width="16"
                            onClick={() => { setShowPassword(true); }}
                        />
                }
            </TextField.Slot>
        </TextField.Root>

    )

});

// Props
PasswordInput.propTypes = {
    placeholder: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    disabled: PropTypes.bool
};

// Export Component
export default PasswordInput;
