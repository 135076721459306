// Imports
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// Utils
//import assets from '../../../utils/assets';
import assets from '@vikingevents/viking-fe-util-assets';

// Assets
import TestCard from '../../../assets/images/test-card.png';

// Components
import { Flex } from '@radix-ui/themes';

// Image component
const Image = (props) => {

    let isTall = props.isTall;
    let isWide = props.isWide;
    
    // Make sure only one is true
    if (isTall && isWide) {
        isTall = false;
    }

    // Work out classes
    let classes = "img-full-width";
    if (isTall) {
        classes = "img-full-height";
    }

    // Square overrides all others
    if (props.isSquare) {
        classes = "img-full-square";
    }


    if (props.asset) {
        
        return (

            <Flex
                width="100%"
                height="100%"
                justify="center"
                align="center"
                overflow="hidden"
            >

                    <img 
                        src={props.asset} 
                        alt={props.alt} 
                        className={classes} 
                    />

            </Flex>

        );

    }

	// Return component
	return (

        <Flex
            width="100%"
            height="100%"
            justify="center"
            align="center"
            overflow="hidden"
            position="relative"
        >


            <img 
                src={TestCard} 
                alt="Loading image..." 
                className="img-full-square"
            />

            <Flex
                position="absolute"
                top="0"
                left="0"
                bottom="0"
                right="0"
                width="100%"
                height="100%"
                justify="center"
                align="center"
                overflow="hidden"
            >

                <LazyLoadImage
                    alt={props.alt}
                    effect="blur"
                    wrapperProps={{
                        style: {transitionDelay: "1s"},
                    }}
                    src={assets.getURL('viking', props.src)} 
                    className={classes}
                />

            </Flex>        

        </Flex>

    )

};

// Prop Types
Image.propTypes = {
    src: PropTypes.string,
    asset: PropTypes.string,
    alt: PropTypes.string.isRequired,
    isTall: PropTypes.bool,
    isWide: PropTypes.bool,
    isSquare: PropTypes.bool,
}

// Export Component
export default Image;

/* 



            */