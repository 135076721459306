// Imports
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

// Components
import Modal from '../../../elements/Modal';
import InputHeading from '../../../inputs/InputHeading';
import TextInput from '../../../inputs/TextInput';
import PrimaryButton from '../../../buttons/PrimaryButton';
import { Flex, Text} from '@radix-ui/themes';

// Utils
import defaults from '../../../../utils/defaults';
import api from '@vikingevents/viking-fe-util-api';
import notification from '@vikingevents/viking-fe-util-notification';
import validate from '@vikingevents/viking-fe-util-validate';
import format from '@vikingevents/viking-fe-util-format';

// EditUsers component
const EditUsers = (props) => {

    // State
    const [editUserIsLoading, setEditUserIsLoading] = useState(false);

    // Refs
    const editUserNameInput = useRef(null);
	const editUseEmailInput = useRef(null);

    // Edit User
	const editUser = () => {

		// Get values
		let name = editUserNameInput.current.value;
		let email = editUseEmailInput.current.value;

        // Validate
        if (name === props.selectedUser.name && email === props.selectedUser.email) {
            notification.error("No changes detected.");
        }

        if (name && !validate.fullName(name)) {
			notification.error("Please enter a valid name.");
			return;
		}

		if (email && !validate.email(email)) {
			notification.error("Please enter a valid email.");
			return;
		}

		if (email && email.split("@")[1] !== "vikingeventsgroup.com") {
			notification.error("Email address must be on the domain vikingeventsgroup.com.");
			return;
		}

        if (!email) {
            email = undefined;
        }
        if (!name) {
            name = undefined;
        }

		// Set loading
		setEditUserIsLoading(true);

		// Add user
		api.send({
			method: "POST",
			cacheKey: defaults.consts.CACHE_KEY,
			sessionKey: defaults.consts.SESSION_KEY,
			api: "viking_internal",
			endpoint: "/user/update",
			requiresAuth: true,
			data: {
                id: props.selectedUser._id,
                new: {
                    name: name,
                    email: email
                },
                old: {
                    name: props.selectedUser.name,
                    email: props.selectedUser.email
                }
			}
		}).then((response) => {

			notification.success("User updated successfully.");
			let usersUpdated = props.users;
            let usersStaticUpdated = props.usersStatic;
            let updatedUser = response.data.user;
            let usersIndex = format.findIndex(usersUpdated, "_id", updatedUser._id);
            let usersStaticIndex = format.findIndex(usersStaticUpdated, "_id", updatedUser._id);

            if (usersIndex !== -1) {
                usersUpdated[usersIndex] = updatedUser;
            }

            if (usersStaticIndex !== -1) {
                usersStaticUpdated[usersStaticIndex] = updatedUser;
            }

            props.setUsers(usersUpdated);
            console.log(usersUpdated);
            props.setUsersStatic(usersStaticUpdated);
            console.log(usersStaticUpdated);
			setEditUserIsLoading(false);
            props.setSelectedUser(null);
            props.toggleEditUser(false);

		}).catch((error) => {

			setEditUserIsLoading(false);

		});

	};

	// Return component
	return (

        <Modal
            title="Edit User"
            closeCallback={() => { props.toggleEditUser(false); }} 
        >

            <Flex
                width="100%"
                minHeight="100px"
                overflowX="hidden"
                overflowY="auto"
                direction="column"
                mt="20px"
            >

                <Flex
                    width="50%"
                    height="200px"
                    direction="column"
                    pr="5px"
                    pl="5px"
                    mb="20px"
                >

                    <InputHeading>Name:</InputHeading>

                    <Text
                        className="font-size-14"
                        mt="10px"
                        mb="10px"
                    >Current: {props.selectedUser.name}</Text>

                    <TextInput
                        placeholder="Full Name"
                        type="text"
                        ref={editUserNameInput}
                    />

                    <InputHeading>Email:</InputHeading>

                    <Text
                        className="font-size-14"
                        mt="10px"
                        mb="10px"
                    >Current: {props.selectedUser.email}</Text>

                    <TextInput
                        placeholder="email@vikingeventsgroup.com"
                        type="email"
                        ref={editUseEmailInput}
                    />

                </Flex>

                <Flex
                    width="50%"
                    mt="20px"
                    pl="5px"
                >

                    <PrimaryButton
                        onClick={() => { editUser(); }}
                        isLoading={editUserIsLoading}
                    >Edit User</PrimaryButton>

                </Flex>

            </Flex>

        </Modal>

    )

};

// Props
EditUsers.propTypes = {
    toggleEditUser: PropTypes.func.isRequired,
    users: PropTypes.array.isRequired,
    usersStatic: PropTypes.array.isRequired,
    setUsers: PropTypes.func.isRequired,
    setUsersStatic: PropTypes.func.isRequired,
    selectedUser: PropTypes.object.isRequired,
    setSelectedUser: PropTypes.func.isRequired
};

// Export Component
export default EditUsers;
