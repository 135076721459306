// Imports
import React, { useRef } from 'react';
import PropTypes from 'prop-types';

// Components
import { Flex, Badge, Tooltip } from '@radix-ui/themes'
import TextInput from '../TextInput';
import InputHeading from '../InputHeading';

// Icons 
import { Cross2Icon, InfoCircledIcon } from '@radix-ui/react-icons';

// TagInput component
const TagInput = React.forwardRef((props, ref) => {

    // Refs
    const textInput = useRef(null);

    // Handle text input change
    const handleTextInputChange = () => {

        // Get value
        let value = textInput.current.value;

        // Check if last character is a comma
        if (value.charAt(value.length - 1) === ",") {
            
            // Check the tag doesn't already exist
            let tagExists = props.tags.find(x => x.label === value.slice(0, -1));
            if (tagExists) {
                return;
            }

            if (value.includes(",")) {
                value = value.slice(0, -1);
            }

            value = value.trim();

            props.setTags([...props.tags, value]);
            textInput.current.value = "";

        }

    };

    // Handle delete tag
    const handleDeleteTag = (index) => {

        // Remove tag
        let newTags = [...props.tags];
        newTags.splice(index, 1);
        props.setTags(newTags);

    };

	// Return component
	return (

        <Flex
            width="50%"
            minHeight="10px"
            overflowX="hidden"
            overflowY="auto"
            direction="column"
        >

            <Flex
                align="center"
            >
                <InputHeading>{`${props.title}:`}</InputHeading>
                <Tooltip 
                    content={`Use a comma to separate ${props.title.toLowerCase()}.`}
                >
                    <InfoCircledIcon color="orange" />
                </Tooltip>
            </Flex>

            <TextInput 
                placeholder={props.placeholderText}
                type="text"
                ref={textInput}
                onChangeCallback={handleTextInputChange}
            />

            <Flex
                width="100%"
                minHeight="10px"
                overflowX="hidden"
                overflowY="auto"
                wrap="wrap"
            >
                {props.tags.map((tag, index) => (
                    <Badge
                        color="orange"
                        key={index}
                        mr="5px"
                        mt="5px"
                    >{tag} <Cross2Icon 
                        onClick={() => { handleDeleteTag(index); }}
                        className="cursor-pointer"
                    /></Badge>
                ))}
            </Flex>

        </Flex>

    )

});

// Props
TagInput.propTypes = {
    setTags: PropTypes.func.isRequired,
    tags: PropTypes.array.isRequired,
    placeholderText: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
};

// Export Component
export default TagInput;
