// Imports
import React, { useRef, useState } from 'react';

// Components
import Meta from '../../Meta';
import { Flex, Heading } from '@radix-ui/themes';
import Image from '../../elements/Image';
import TextInput from '../../inputs/TextInput';
import InputHeading from '../../inputs/InputHeading';
import PasswordInput from '../../inputs/PasswordInput';
import PrimaryButton from '../../buttons/PrimaryButton';
import PasswordStrengthCard from '../../cards/PasswordStrengthCard';

// Utils
import defaults from '../../../utils/defaults';
import notification from '@vikingevents/viking-fe-util-notification';
import api from '@vikingevents/viking-fe-util-api';
import spinner from '@vikingevents/viking-fe-util-spinner';
import session from '@vikingevents/viking-fe-util-session';
import validate from '@vikingevents/viking-fe-util-validate';

// Assets
import Logo from '../../../assets/logos/logo-trans-white.png';

// LoginPage component
const LoginPage = () => {

    // Refs
    const usernameRef = useRef();
    const passwordRef = useRef();
    const newPasswordRef = useRef();
    const confirmNewPasswordRef = useRef();

    // State
    const [showNewPassword, setShowNewPassword] = useState(false); 
    const [passwordValidation, setPasswordValidation] = useState({
        has8Chars: false,
        hasNumber: false,
        hasUppercase: false,
        hasLowercase: false,
        hasSpecial: false
    });

    // Submit login
    const submitLogin = async () => {

        // Get values
        let username = usernameRef.current.value;
        let password = passwordRef.current.value;

        // Check values
        if (username && password) {

            // Show spinner
            spinner.show();

            api.send({
                method: "POST",
                cacheKey: defaults.consts.CACHE_KEY,
                sessionKey: defaults.consts.SESSION_KEY,
                api: "viking_internal",
                endpoint: "/user/login",
                data: {
                    username: username,
                    password: password
                }
            }).then((response) => {

                // Check response
                if (response.status === 200) {

                    if (response.data.requiresNewPassword) {

                        // Hide spinner
                        spinner.hide();

                        // Show new password
                        setShowNewPassword(true);

                    } else {

                         // Reload
                        window.location.reload(false);

                    }

                } else {

                    // Show error
                    notification.error("Invalid username or password");

                    // Hide spinner
                    spinner.hide();

                }

            }).catch((error) => {
                
                spinner.hide();

            });

        } else {

            // Show error
            notification.error('Please enter your username and password');

        }

    }

    // newPasswordChange
    const newPasswordChange = () => {

        let validated = validate.password(newPasswordRef.current.value);

        setPasswordValidation(validated);
        
    }

    // Save new password 
    const saveNewPassword = async () => { 

        // Get values
        let newPassword = newPasswordRef.current.value;
        let confirmNewPassword = confirmNewPasswordRef.current.value;

        // Validate
        let validated = validate.password(newPassword);
        if (!validated.has8Chars) {
            notification.error('Password must be at least 8 characters');
            return;
        }
        if (!validated.hasNumber) {
            notification.error('Password must contain a number');
            return;
        }
        if (!validated.hasUppercase) {
            notification.error('Password must contain an uppercase letter');
            return;
        }
        if (!validated.hasLowercase) {
            notification.error('Password must contain a lowercase letter');
            return;
        }
        if (!validated.hasSpecial) {
            notification.error('Password must contain a special character');
            return;
        }
        if (newPassword !== confirmNewPassword) {
            notification.error('Passwords do not match');
            return;
        }

        // Show spinner
        spinner.show();

        api.send({
            method: "POST",
            cacheKey: defaults.consts.CACHE_KEY,
            sessionKey: defaults.consts.SESSION_KEY,
            api: "viking_internal",
            endpoint: "/user/update/password",
            data: {
                password: newPassword,
                confirmPassword: confirmNewPassword
            },
            requiresAuth: true
        }).then((response) => {

            // Check response
            if (response.status === 200) {

                // Reload
                window.location.reload(false);

            } else {

                // Show error
                notification.error("Invalid username or password");

                // Hide spinner
                spinner.hide();

            }

        }).catch((error) => {
            
            spinner.hide();

        });

    }

	// Return component
	return (

        <Flex
            width="100%"
            height="100vh"
            justify="center"
            align="center"
            className="background-primary"
        >

			<Meta
				title="Login"
			/>

            <Flex
                className="background-white"
                width="40%"
                height="100%"
                align="center"
                justify="center"
            >

            {!showNewPassword && 
            
                <Flex
                    width="90%"
                    minHeight="10px"
                    direction="column"
                >

                    <Heading
                        className="font-family-black"
                        mb="40px"
                    >Login</Heading>

                    <InputHeading>Username:</InputHeading>

                    <TextInput 
                        placeholder="username" 
                        type="text" 
                        ref={usernameRef} 
                    />

                    <InputHeading>Password:</InputHeading>

                    <PasswordInput
                        placeholder="password"
                        ref={passwordRef}
                    />

                    <PrimaryButton
                        onClick={submitLogin}
                    >Login</PrimaryButton>

                </Flex>
            
            }

            {showNewPassword && 
                        
                <Flex
                    width="90%"
                    minHeight="10px"
                    direction="column"
                >

                    <Heading
                        className="font-family-black"
                        mb="40px"
                    >Update Password</Heading>

                    <InputHeading>New Password:</InputHeading>

                    <PasswordInput
                        placeholder="new password"
                        onChange={newPasswordChange}
                        ref={newPasswordRef}
                    />

                    <PasswordStrengthCard 
                        has8Chars={passwordValidation.has8Chars}
                        hasNumber={passwordValidation.hasNumber}
                        hasUppercase={passwordValidation.hasUppercase}
                        hasLowercase={passwordValidation.hasLowercase}
                        hasSpecial={passwordValidation.hasSpecial}
                    />

                    <InputHeading>Confirm New Password:</InputHeading>

                    <PasswordInput
                        placeholder="confirm new password"
                        ref={confirmNewPasswordRef}
                    />

                    <PrimaryButton
                        onClick={saveNewPassword}
                    >Save Password</PrimaryButton>

                </Flex>
            
            }

            </Flex>

            <Flex
                className="background-primary"
                width="60%"
                height="100%"
                align="center"
                justify="center"
                direction="column"
            >

                <Flex
                    width="600px"
                    height="600px"
                >

                    <Image
                        asset={Logo}
                        alt="Viking VMS"
                    />

                </Flex>

                <Heading
                    className="font-family-black colour-white font-size-38"
                    mt="40px"
                >VMS</Heading>

            </Flex>
            
        </Flex>

    )

};

// Export Component
export default LoginPage;