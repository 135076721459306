// Imports
import React from 'react';
import PropTypes from 'prop-types';
import { AlertDialog, Button, Flex } from '@radix-ui/themes'

// Alert component
const Alert = (props) => {

	// Return component
	return (

        <AlertDialog.Root>
            <AlertDialog.Trigger>
                {props.children}
            </AlertDialog.Trigger>
            <AlertDialog.Content maxWidth="450px">
                <AlertDialog.Title>{props.title}</AlertDialog.Title>
                <AlertDialog.Description size="2">{props.message}</AlertDialog.Description>

                <Flex gap="3" mt="4" justify="end">
                    <AlertDialog.Cancel>
                        <Button variant="soft" color="gray">
                            Cancel
                        </Button>
                    </AlertDialog.Cancel>
                    <AlertDialog.Action>
                        <Button 
                            variant="solid" 
                            color="red"
                            onClick={props.confirmAction}
                        >
                            {props.confirmText}
                        </Button>
                    </AlertDialog.Action>
                </Flex>
            </AlertDialog.Content>
        </AlertDialog.Root>

    )

};

// Props
Alert.propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    confirmText: PropTypes.string.isRequired,
    confirmAction: PropTypes.func.isRequired,
};

// Export Component
export default Alert;
