// Imports
import React from 'react';
import { Link } from 'react-router-dom';

// Components
import { Flex, Text, Separator } from '@radix-ui/themes';
import Image from '../../elements/Image';

// Utils
//import session from '../../../utils/session';
import session from '@vikingevents/viking-fe-util-session';
import defaults from '../../../utils/defaults';

// Assets
import LogoTransWhite from '../../../assets/logos/logo-trans-white.png'

// Header Component
const Header = () => {

    // Get session
    let sessionObj = session.get(defaults.consts.SESSION_KEY);

    // Logout
    const logout = () => {

        // Remove session
        session.remove(defaults.consts.SESSION_KEY);

        // Reload
        window.location.href = "/";

    };

	// Return component
	return (

        <Flex
            width="100%"
            height="100px"
            className="background-primary z-index-90"
            justify="between"
            align="center"
            position="fixed"
        >

            <Link to="/" >

                <Flex
                    width="200px"
                    height="80px"
                    ml="40px"
                    overflow="hidden"
                    justify="start"
                    align="center"
                    className="cursor-pointer"
                >

                    <Image
                        asset={LogoTransWhite}
                        alt="Viking Logo"
                        isTall
                    />

                    <Text
                        className="colour-white-no-hover font-family-black font-size-22"
                    >VMS</Text>

                </Flex>

            </Link>

            <Flex
                minWidth="20px"
                height="40px"
                overflowX="auto"
                overflowY="hidden"
                mr="40px"
                justify="end"
                align="center"
            >

                <Text
                    className="colour-white"
                    mr="10px"
                >{sessionObj.user.email}</Text>

                <Separator 
                    orientation="vertical"
                    size="1" 
                    className="background-white"
                    mr="10px"
                />

                <Text
                    className="colour-white"
                    mr="10px"
                >
                    <Link 
                        to="/account"
                        className="colour-white-no-hover"
                    >Account</Link>
                </Text>

                <Separator 
                    orientation="vertical"
                    size="1" 
                    className="background-white"
                    mr="10px"
                />

                <Text 
                    onClick={() => { logout(); }}
                    className="colour-white cursor-pointer"
                >Logout</Text>

            </Flex>

        </Flex>

	);

};

// Export
export default Header;
