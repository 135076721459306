// Imports
import React from 'react';

// Components
import Page from '../../elements/Page';
import Section from '../../elements/Section';

// AccountPage component
const AccountPage = () => {

	// Return component
	return (

		<Page 
			title="Account"
		>

			<Section
				title="Account"
			>

			</Section>

		</Page>

    )

};

// Export Component
export default AccountPage;
