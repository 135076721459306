// Imports
import React from 'react';

// Components
import Page from '../../elements/Page';
import Section from '../../elements/Section';

// HomePage component
const HomePage = () => {

	// Return component
	return (

		<Page 
			title="Home"
		>

			<Section
				title="Home Page"
			>

			</Section>

		</Page>

    )

};

// Export Component
export default HomePage;
