// Imports
import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Spinner as RadixSpinner } from '@radix-ui/themes'

// Spinner component
const Spinner = (props) => {

	// Return component
	return (

        <Flex
            width="50px"
            height="50px"
            className="background-very-light-grey radius-10"
            position="fixed"
            bottom="40px"
            right="40px"
            justify="center"
            align="center"
            id="spinner"
            display="none"
        >
            <RadixSpinner size="3" />
        </Flex>

    )

};

// Props
Spinner.propTypes = {
};

// Export Component
export default Spinner;
