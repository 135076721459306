// Imports
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@radix-ui/themes'

// IconButton component
const IconButton = (props) => {

    let colour = 'orange';
    if (props.isDanger) {
        colour = 'red';
    }
    if (props.isSecondary) {
        colour = 'gray';
    }

	// Return component
	return (

        <Button
            color={colour}
            onClick={ () => { props.onClick() }}
            size={props.isSmall ? '1' : '2' }
            loading={props.isLoading}
            mr={props.addMarginRight ? "20px" : "0px"}
        >{props.icon}{props.children}</Button>

    )

};

// Props
IconButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    icon: PropTypes.object.isRequired,
    children: PropTypes.string,
    isSmall: PropTypes.bool,
    isDanger: PropTypes.bool,
    isSecondary: PropTypes.bool,
    isLoading: PropTypes.bool,
    addMarginRight: PropTypes.bool
};

// Export Component
export default IconButton;
