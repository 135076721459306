// Imports
import React from 'react';
import { NavLink } from 'react-router-dom';

// Components
import { Flex, Text, Heading } from '@radix-ui/themes';
import PrimaryButton from '../buttons/PrimaryButton';

// ErrorBoundary Class
class ErrorBoundary extends React.Component {

	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		// You can also log the error to an error reporting service
		// LogErrorToMyService(error, errorInfo);
		console.error(error);
		console.error(errorInfo);
		// TODO: Do proper log with notification to dev team
	}

	// Go back
	goBack() {
		this.context.router.history.goBack;
	}

	render() {

		// Check if error
		if (this.state.hasError) {

			// Return error page
			return (

				<Flex
					width="100%"
					height="100vh"
					justify="center"
					align="center"
					className="background-primary"
				>

					<Flex
						width="500px"
						minHeight="100px"
						overflowX="hidden"
						overflowY="auto"
						className="background-white radius-10"
						padding="20px"
						direction="column"
						justify="center"
						align="center"
						pb="40px"
					>

						<Heading
							className="font-family-black font-size-32"
							mt="40px"
							mb="40px"
						>Oops.</Heading>

						<Text
							className="font-family-normal text-align-center"
							ml="20px"
							mr="20px"
						>It looks like something has gone wrong or this page may be broken.</Text>

						<Text
							className="font-family-normal text-align-center"
							mt="20px"
							ml="20px"
							mr="20px"
						>Don't worry, our team has been notified and will get this fixed asap!</Text>

					</Flex>

				</Flex>

			);
		}

		// Return app
		return this.props.children;

	}

}

export default ErrorBoundary;
