// Imports
import React from 'react';

// Components
import Page from '../../../elements/Page';
import Section from '../../../elements/Section';

// ReportIssuePage component
const ReportIssuePage = () => {

	// Return component
	return (

		<Page 
			title="Assets - Report Issue"
		>

			<Section
				title="Assets - Report Issue"
			>

			</Section>

		</Page>

    )

};

// Export Component
export default ReportIssuePage;
