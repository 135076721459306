// Imports
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components
import Modal from '../../../elements/Modal';
import PrimaryButton from '../../../buttons/PrimaryButton';
import Alert from '../../../feedback/Alert';
import { Table, Flex, SegmentedControl, CheckboxCards, Text } from '@radix-ui/themes';

// Utils
import defaults from '../../../../utils/defaults';
import api from '@vikingevents/viking-fe-util-api';
import notification from '@vikingevents/viking-fe-util-notification';
import spinner from '@vikingevents/viking-fe-util-spinner';
import session from '@vikingevents/viking-fe-util-session';
import format from '@vikingevents/viking-fe-util-format';

// Icons
import { TrashIcon } from '@radix-ui/react-icons';

// ViewPermissions component
const ViewPermissions = (props) => {

    // State
    const [sessionData, setSessionData] = useState(session.get(defaults.consts.SESSION_KEY));

    const [permissionsViewControl, setPermissionsViewControl] = useState("view");
    const [addPermissionsCheckboxCards, setAddPermissionsCheckboxCards] = useState([]);
	const [addPermissionsSubmitIsLoading, setAddPermissionsSubmitIsLoading] = useState(false);

    // Delete permission
	const deletePermission = (permission) => {

		// Set loading
		spinner.show();

		// Delete permission
		api.send({
			method: "POST",
			cacheKey: defaults.consts.CACHE_KEY,
			sessionKey: defaults.consts.SESSION_KEY,
			api: "viking_internal",
			endpoint: "/user/update/permissions",
			requiresAuth: true,
			data: {
				user: props.selectedUser,
				permission: permission,
				action: "remove"
			}
		}).then((response) => {

			spinner.hide();
			notification.success("Permission deleted successfully.");
			let usersUpdated = props.users;
			let usersStaticUpdated = props.usersStatic;
			let updatedUser = response.data.user;
			let usersIndex = format.findIndex(usersUpdated, "_id", updatedUser._id);
			let usersStaticIndex = format.findIndex(usersStaticUpdated, "_id", updatedUser._id);

			if (usersIndex !== -1) {
				usersUpdated[usersIndex] = updatedUser;
			}

			if (usersStaticIndex !== -1) {
				usersStaticUpdated[usersStaticIndex] = updatedUser;
			}

			props.setUsers(usersUpdated);
			props.setUsersStatic(usersStaticUpdated);
            props.toggleViewPermissions(false);
			props.setSelectedUser(null);

		}).catch((error) => {

			spinner.hide();

		});

	}

	// Add permission checkbox card changed
	const addPermissionsCheckboxCardsChanged = (value) => {

		let values = addPermissionsCheckboxCards;
		if (addPermissionsCheckboxCards.includes(value)) {
			values = values.filter((item) => item !== value);
		} else {
			values.push(value);
		}

		setAddPermissionsCheckboxCards(values);

	}

	// Add permissions submit 
	const addPermissionsSubmit = () => {
		
        if (addPermissionsCheckboxCards.length === 0) {
            notification.error("Please select at least one permission.");
            return;
        }

        setAddPermissionsSubmitIsLoading(true);

        // Add permissions
        api.send({
            method: "POST",
            cacheKey: defaults.consts.CACHE_KEY,
            sessionKey: defaults.consts.SESSION_KEY,
            api: "viking_internal",
            endpoint: "/user/update/permissions",
            requiresAuth: true,
            data: {
                user: props.selectedUser,
                permission: addPermissionsCheckboxCards,
                action: "add"
            }
        }).then((response) => {

            setAddPermissionsSubmitIsLoading(false);
            notification.success("Permissions added successfully.");
            let usersUpdated = props.users;
            let usersStaticUpdated = props.usersStatic;
            let updatedUser = response.data.user;
            let usersIndex = format.findIndex(usersUpdated, "_id", updatedUser._id);
            let usersStaticIndex = format.findIndex(usersStaticUpdated, "_id", updatedUser._id);

            if (usersIndex !== -1) {
                usersUpdated[usersIndex] = updatedUser;
            }

            if (usersStaticIndex !== -1) {
                usersStaticUpdated[usersStaticIndex] = updatedUser;
            }

            props.setUsers(usersUpdated);
            props.setUsersStatic(usersStaticUpdated);
            props.toggleViewPermissions(false);
            props.setSelectedUser(null);

        }).catch((error) => {

            setAddPermissionsSubmitIsLoading(false);

        });

	}

	// Return component
	return (

        <Modal
            title="User Permissions"
            closeCallback={() => { props.toggleViewPermissions(false); }}
        >

            <SegmentedControl.Root 
                defaultValue="view"
                mt="20px"
            >

                <SegmentedControl.Item 
                    value="view"
                    onClick={() => { setPermissionsViewControl("view"); }}
                >View</SegmentedControl.Item>

                <SegmentedControl.Item 
                    value="add"
                    onClick={() => { setPermissionsViewControl("add"); }}
                >Add</SegmentedControl.Item>

            </SegmentedControl.Root>

            {permissionsViewControl === "view" &&

                <Flex
                    width="100%"
                    minHeight="100px"
                    overflowX="hidden"
                    overflowY="auto"
                    direction="column"
                    mt="20px"
                >

                    <Table.Root
                        size="2"
                        layout="auto"
                    >

                        <Table.Header>

                            <Table.Row>

                                <Table.ColumnHeaderCell>Name</Table.ColumnHeaderCell>

                                {sessionData.user.permissions.includes("UsersFullAccess") &&
                                
                                    <Table.ColumnHeaderCell
                                        align="center"
                                    >Remove</Table.ColumnHeaderCell>

                                }

                            </Table.Row>

                        </Table.Header>
                    
                        <Table.Body>

                            {props.selectedUser.permissions.map((permission, index) => (

                                <Table.Row
                                    key={index}
                                >

                                    <Table.RowHeaderCell>{permission}</Table.RowHeaderCell>

                                    {sessionData.user.permissions.includes("UsersFullAccess") &&
                                
                                        <Table.Cell
                                            align="center"
                                        >
                                            
                                            <Alert
                                                title="Delete Permission"
                                                message="This will stop the users from accessing the respective features."
                                                confirmText="Delete"
                                                confirmAction={ () => { deletePermission(permission); }}
                                            >

                                                <TrashIcon 
                                                    className="cursor-pointer colour-danger-hover"
                                                    width="20px"
                                                    height="20px"
                                                    
                                                />

                                            </Alert>

                                        </Table.Cell>

                                    }

                                </Table.Row>

                            ))}

                        </Table.Body>

                    </Table.Root>

                </Flex>

            }

            {permissionsViewControl === "add" &&

                <Flex
                    width="100%"
                    minHeight="100px"
                    overflowX="hidden"
                    overflowY="auto"
                    direction="column"
                    mt="20px"
                >

                    <CheckboxCards.Root 
                        defaultValue={[]} 
                        columns={{ initial: "1", sm: "2" }}
                        color="orange"
                        size="1"
                    >

                        {props.permissions.map((permission, index) => {

                            if (!props.selectedUser.permissions.includes(permission.name)) {
                                return(
                            
                                    <CheckboxCards.Item 
                                        value={permission.name}
                                        key={index}
                                        color="orange"
                                        onClick={() => { addPermissionsCheckboxCardsChanged(permission.name); }}
                                    >

                                        <Flex 
                                            direction="column" width="100%"
                                        >

                                            <Text>{permission.name}</Text>

                                        </Flex>

                                    </CheckboxCards.Item>
                            
                                );
                            }
                        
                        })}

                    </CheckboxCards.Root>

                    <Flex
                        width="50%"
                        mt="20px"
                    >

                        <PrimaryButton
                            onClick={() => { addPermissionsSubmit(); }}
                            isLoading={addPermissionsSubmitIsLoading}
                        >Add Permissions</PrimaryButton>

                    </Flex>

                </Flex>

            }

        </Modal>

    )

};

// Props
ViewPermissions.propTypes = {
    toggleViewPermissions: PropTypes.func.isRequired,
    selectedUser: PropTypes.object.isRequired,
    users: PropTypes.array.isRequired,
    usersStatic: PropTypes.array.isRequired,
    setSelectedUser: PropTypes.func.isRequired,
    setUsers: PropTypes.func.isRequired,
    setUsersStatic: PropTypes.func.isRequired,
    permissions: PropTypes.array.isRequired
}

// Export Component
export default ViewPermissions;
