// Imports
import React from 'react';
import { Helmet } from "react-helmet";
import PropTypes from 'prop-types';

// Meta component
const Meta = (props) => {

	// Work out title
    let title = 'VMS';
    if (props.title !== "Home") {
		title += ` | ${props.title}`;
    }

	return (

		<Helmet>

			<title>{title}</title>

		</Helmet>

	);

};

Meta.propTypes = {
	title: PropTypes.string.isRequired,
};

export default Meta;

/*



    //Work out url
    let domain = props.domain.replace("https://", "");
    domain = props.domain.replace("http://", "");
    let url = `https://${domain}${props.pathname}`;

    // Work out image
    let image = props.image;
    if (props.image === "default") {
        image = "https://ava-open.s3.amazonaws.com/branding/logo-large.png";
	}

*/

/*

<meta name="description" content={props.description} />

            <link rel="canonical" href={url} />

            <meta property="og:title" content={title} />
            <meta property="og:description" content={props.description} />
            <meta property="og:url" content={url} />
            <meta property="og:image" content={image} />


            <meta name="twitter:title" content={title}/>
            <meta name="twitter:description" content={props.description} />


*/