// Imports
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import Modal from '../../../elements/Modal';
import PrimaryButton from '../../../buttons/PrimaryButton';
import SelectInput from '../../../inputs/SelectInput';
import InputHeading from '../../../inputs/InputHeading';
import { Flex, Text, Heading, Badge, Skeleton } from '@radix-ui/themes';
import Dropzone from 'react-dropzone-uploader'
import ReactCustomSearchList from 'react-custom-search-list';

// Utils
import defaults from '../../../../utils/defaults';
import api from '@vikingevents/viking-fe-util-api';
import notification from '@vikingevents/viking-fe-util-notification';
import format from '@vikingevents/viking-fe-util-format';
import spinner from '@vikingevents/viking-fe-util-spinner';

// UploadMedia component
const UploadMedia = (props) => {

    // State
    const [saveIsLoading, setSaveIsLoading] = useState(false);
    const [s3FolderKey, setS3FolderKey] = useState(Date.now());
    const [files, setFiles] = useState([]);
    const [uploadIsVisible, setUploadIsVisible] = useState(true);
    const [editIsVisible, setEditIsVisible] = useState(false);
    const [mediaTags, setMediaTags] = useState(props.mediaTags);

    // Get upload params for Dropzone
    const getUploadParams = async ({ file, meta: { name } }) => {

        let folder = s3FolderKey;
        if (!s3FolderKey) {
            folder = Date.now();
            setS3FolderKey(folder);
        }

        // Get presigned URL
        const response = await api.send({
            method: "GET",
            cacheKey: defaults.consts.CACHE_KEY,
            sessionKey: defaults.consts.SESSION_KEY,
            api: "viking_internal",
            endpoint: `/s3/generate/presigned/upload/url/${folder}/${name}`,
            requiresAuth: true
        })

        // Create return object
        let fileURL = response.data.url;
        let returnObject = { body: file, meta: { fileURL }, url: fileURL, method: 'PUT' }

        return returnObject

    }
    
    // Handle change status
    const handleChangeStatus = (fileWithMeta, status) => {
        
        switch (status) {
            case 'rejected_file_type':
                notification.error("File type not accepted.");
                break;
            case 'rejected_max_files':
                notification.error("Max files exceeded.");
                break;
            case 'preparing':
                break;
            case 'error_file_size':
                notification.error("File size to large.");
                break;
            case 'error_validation':
                notification.error("File validation failed.");
                break;
            case 'ready':
                break;
            case 'started':
                spinner.show();
                break;
            case 'getting_upload_params':
                spinner.show();
                break;
            case 'error_upload_params':
                notification.error("Error getting upload params.");
                break;
            case 'uploading':
                spinner.show();
                break;
            case 'exception_upload':
                notification.error("Upload exception.");
                break;
            case 'aborted':
                break;
            case 'restarted':
                spinner.show();
                break;
            case 'removed':
                break;
            case 'error_upload':
                notification.error("Upload error.");
                break;
            case 'headers_received':
                break;
            case 'done':
                spinner.hide();
                break;
            default:
                break;

        }
        
    }
    
    // Handle submit
    const handleSubmit = async (files, allFiles) => {

        spinner.show();

        // Format
        let formattedFiles = [];
        allFiles.forEach((file) => {

            // Add Type tags
            let tags = [];
            if (file.meta.type.includes("image")) {
                tags.push({
                    value: "t_image",
                    label: "Image"
                });
            }
            if (file.meta.type.includes("audio")) {
                tags.push({
                    value: "t_audio",
                    label: "Audio"
                });
            }
            if (file.meta.type.includes("video")) {
                tags.push({
                    value: "t_video",
                    label: "Video"
                });
            }

            // Add to formattedFiles
            formattedFiles.push({
                name: file.meta.name,
                size: file.meta.size,
                type: file.meta.type,
                previewURL: null,
                tags: tags,
                tagSearchResults: [],
                tagSearchValue: "",
                dimensions: {
                    width: file.meta.width || null,
                    height: file.meta.height || null
                }
            });


        });

        await api.send({
            method: "POST",
            cacheKey: defaults.consts.CACHE_KEY,
            sessionKey: defaults.consts.SESSION_KEY,
            api: "viking_internal",
            endpoint: "/s3/generate/presigned/download/urls",
            requiresAuth: true,
            data: {
                files: formattedFiles,
                folder: s3FolderKey
            }
        }).then((response) => {
            formattedFiles = response.data.files;
        }).catch((error) => {
            notification.error("Error getting preview urls.");
        });

        
        setFiles(formattedFiles);
        setUploadIsVisible(false);
        setEditIsVisible(true);
        spinner.hide();

    }

    // Year tag input changed
    const handleYearTagChange = (value) => {

        if (value === 'select') {
            return;
        }
        
        // Split value
        let splitValue = value.split("_");
        let index = splitValue[0];
        let year = splitValue[1];

        // Get file
        let updatedFiles = files;
        let updatedFile = updatedFiles[index];

        // Create tag
        let tag = {
            value: `y_${year}`,
            label: year
        }

        // Find index of tag where value includes a y_
        let tagFound = updatedFile.tags.find(x => x.value.includes('y'));

        // Add tag
        if (tagFound) {
            let tagIndex = updatedFile.tags.findIndex(x => x.value === tagFound.value);
            updatedFile.tags[tagIndex] = tag;
        } else {
            updatedFile.tags.push(tag);
        }

        // Update files
        updatedFiles[index] = updatedFile;
        setFiles([...updatedFiles]);

    }

    // Handle add tag change
    const handleAddTagChange = (value, index) => {
                
        // Check if last character is a space or a comma
        if (value.charAt(value.length - 1) === " " || value.charAt(value.length - 1) === ",") {
            
            // Check the tag doesn't already exist
            let tagExists = files[index].tags.find(x => x.label === value.slice(0, -1));
            if (tagExists) {
                return;
            }

            if (value.includes(" ")) {
                value = value.slice(0, -1);
            }
            if (value.includes(",")) {
                value = value.slice(0, -1);
            }

            let updatedFiles = files;
            let updatedFile = updatedFiles[index];

            let tag = {
                value: `d_${value}`,
                label: value
            }
            updatedFile.tags.push(tag);
            updatedFile.tagSearchResults = [];
            updatedFile.tagSearchValue = "";

            updatedFiles[index] = updatedFile;
            setFiles([...updatedFiles]);

            // Add to mediaTags
            let updatedMediaTags = mediaTags;
            let shouldPush = true;
            mediaTags.forEach((mediaTag) => {
                if (mediaTag.value === tag.value) {
                    shouldPush = false;
                }
            });
            if (shouldPush) {
                updatedMediaTags.push(tag);
                setMediaTags([...updatedMediaTags]);
            }   

        } else {

            if (value.length === 0) {
                let updatedFiles = files;
                let updatedFile = updatedFiles[index];
                updatedFile.tagSearchResults = [];
                updatedFile.tagSearchValue = "";
                updatedFiles[index] = updatedFile;
                setFiles([...updatedFiles]);
                return;
            }

            // Search mediaTags for value
            let tagSearchResults = mediaTags.filter(x => x.label.toLowerCase().includes(value.toLowerCase()));
            let updatedFiles = files;
            let updatedFile = updatedFiles[index];
            updatedFile.tagSearchResults = tagSearchResults;
            updatedFile.tagSearchValue = value;
            updatedFiles[index] = updatedFile;
            setFiles([...updatedFiles]);

        }

    }

    // Remove tag
    const handleRemoveTag = (index, tagIndex) => {
        
        let updatedFiles = files;
        let updatedFile = updatedFiles[index];
        updatedFile.tags.splice(tagIndex, 1);
        updatedFiles[index] = updatedFile;
        setFiles([...updatedFiles]);

    }

    // Handle save
    const handleSave = async () => {
        
        setSaveIsLoading(true);

        // Check all files have a year tag
        let haveYearTags = true;
        files.forEach((file) => {
            let yearTag = file.tags.find(x => x.value.includes('y'));
            if (!yearTag) {
                haveYearTags = false;
            }
        });

        if (!haveYearTags) {
            notification.error("All files must have a year tag.");
            setSaveIsLoading(false);
            return;
        }

        // Create file objects
        let filesToSave = [];
        files.forEach((file) => {
            
            filesToSave.push({
                name: file.name,
                size: file.size,
                type: file.type,
                tags: file.tags,
                folder: s3FolderKey,
                dimensions: file.dimensions
            });

        });

        // Save files
        await api.send({
            method: "POST",
            cacheKey: defaults.consts.CACHE_KEY,
            sessionKey: defaults.consts.SESSION_KEY,
            api: "viking_internal",
            endpoint: "/marketing/media/upload",
            requiresAuth: true,
            data: {
                files: filesToSave
            }
        }).then((response) => {
            notification.success("Media uploaded successfully.");
            setSaveIsLoading(false);
            props.toggleUploadMedia(false, response.data);
        }).catch((error) => {
            notification.error("Error uploading media.");
            setSaveIsLoading(false);
        });

    }

	// Return component
	return (

        <Modal
            title="Upload Media"
            closeCallback={() => { props.toggleUploadMedia(false); }} 
        >

            <Flex
                width="100%"
                minHeight="100px"
                overflowX="hidden"
                overflowY="auto"
                direction="column"
                mt="20px"
            >

                {uploadIsVisible &&
                
                    <Dropzone
                        getUploadParams={getUploadParams}
                        onChangeStatus={handleChangeStatus}
                        onSubmit={handleSubmit}
                        styles={{ dropzone: { minHeight: 200, maxHeight: 500 } }}
                        accept="image/*,audio/*,video/*"
                    />

                }

                {editIsVisible &&
                
                    <Flex
                        width="100%"
                        minHeight="100px"
                        overflowX="hidden"
                        overflowY="auto"
                        direction="column"
                    >

                        {files.map((file, index) => {

                            let yearOptions = [];
                            let endYear = new Date().getFullYear();
                            let currentYear = 2019;
                            while (currentYear <= endYear) {
                                yearOptions.push({
                                    value: `${index}_${currentYear}`,
                                    label: currentYear.toString()
                                });
                                currentYear++;
                            }

                            return (

                                <Flex
                                    key={index}
                                >

                                    <Flex
                                        width="100%"
                                        minHeight="10px"
                                        overflowX="hidden"
                                        overflowY="auto"
                                        justify="start"
                                        align="start"
                                        direction="column"
                                        className="border-solid-grey radius-10"
                                        mb="20px"
                                        p="20px"
                                    >

                                        <Heading
                                            size="5"
                                            mb="10px"
                                        >File</Heading>

                                        <Flex
                                            width="100%"
                                            minHeight="10px"
                                            overflowX="hidden"
                                            overflowY="auto"
                                            justify="between"
                                            align="center"
                                        >

                                            <Flex
                                                direction="column"
                                            >

                                                <Text 
                                                    className="font-size-14"
                                                ><strong>Name:</strong> {file.name}</Text>

                                                <Text 
                                                    className="font-size-14"
                                                ><strong>Type:</strong> {file.type}</Text>
                                                
                                                <Text 
                                                    className="font-size-14"
                                                ><strong>Size:</strong> {format.fileSize(file.size)}</Text>

                                            </Flex>

                                            <Flex
                                                width="140px"
                                                height="100px"
                                                justify="center"
                                                align="center"
                                                overflow="hidden"
                                            >

                                                {file.type.includes('image') &&
                                                    <img 
                                                        src={`${file.previewURL}`} 
                                                        alt={file.name} 
                                                        className="image-input-default-image"
                                                    />
                                                }

                                                {file.type.includes('audio') &&
                                                    <audio controls>
                                                        <source 
                                                            src={file.previewURL} 
                                                            type={file.type} 
                                                        />
                                                    </audio>
                                                }

                                                {file.type.includes('video') &&
                                                    <video 
                                                        width="140" 
                                                        height="100" 
                                                        controls
                                                    >
                                                        <source 
                                                            src={file.previewURL} 
                                                            type={file.type}
                                                        />
                                                    </video>
                                                }

                                            </Flex>

                                        </Flex>

                                        <Heading
                                            size="5"
                                            mt="10px"
                                            mb="10px"
                                        >Tags</Heading>

                                        <Flex
                                            mt="10px"
                                            direction="column"
                                        >

                                            <InputHeading>Select Year Taken:</InputHeading>

                                            <SelectInput 
                                                options={yearOptions}
                                                defaultValue={'select'}
                                                onChange={handleYearTagChange}
                                            />

                                        </Flex>
                                        
                                        <Flex
                                            direction="column"
                                        >

                                            <InputHeading>Add Tags:</InputHeading>

                                            <ReactCustomSearchList
                                                placeholder="Add a tag"
                                                value={file.tagSearchValue}
                                                onChange={(e) => { handleAddTagChange(e.target.value, index); }}
                                                onClear={() => { handleAddTagChange("", index); }}
                                                theme="panel"
                                                fullWidth
                                                corner>

                                                {file.tagSearchResults.length > 0 &&

                                                    <Flex
                                                        direction="column"
                                                        p="10px"
                                                    >
                                                
                                                        {file.tagSearchResults.map((tag, tagIndex) => (
                                                                
                                                            <Text
                                                                key={tagIndex}
                                                                mb="5px"
                                                                className="cursor-pointer colour-dark-grey-hover font-size-14"
                                                                onClick={() => { handleAddTagChange(`${tag.label},`, index);}}
                                                            >{tag.label}</Text>
                                                            
                                                        ))}

                                                    </Flex>

                                                }

                                            </ReactCustomSearchList>

                                        </Flex>

                                        <Flex
                                            align="center"
                                            mt="10px"
                                            wrap='wrap'
                                        >

                                            {file.tags.map((tag, tagIndex) => (

                                                <Badge 
                                                    color="orange"
                                                    mr="10px"
                                                    mb="10px"
                                                    key={tagIndex}
                                                    size="3"
                                                >
                                                    {tag.label} 
                                                    {tag.value.includes('d_') &&
                                                        <span
                                                            className="cursor-pointer"
                                                            onClick={() => { handleRemoveTag(index, tagIndex); }}
                                                        >X</span>
                                                    }
                                                </Badge>

                                            ))}

                                        </Flex>

                                    </Flex>

                                </Flex>

                            );
                            
                        })}

                        <PrimaryButton
                            isLoading={saveIsLoading}
                            onClick={() => { handleSave(); }}
                        >Upload</PrimaryButton>

                    </Flex>
                    
                }

            </Flex>

        </Modal>

    )

};

// Props
UploadMedia.propTypes = {
    toggleUploadMedia: PropTypes.func.isRequired,
    mediaTags: PropTypes.array.isRequired
};

// Export Component
export default UploadMedia;
