// Imports
import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';

// Components
import SearchBar from '../../../inputs/SearchBar';
import { Skeleton, Table, Flex } from '@radix-ui/themes';

// Utils
import defaults from '../../../../utils/defaults';
import api from '@vikingevents/viking-fe-util-api';
import notification from '@vikingevents/viking-fe-util-notification';
import spinner from '@vikingevents/viking-fe-util-spinner';
import session from '@vikingevents/viking-fe-util-session';

// ViewUsers component
const ViewUsers = (props) => {

    // State
    const [sessionData, setSessionData] = useState(session.get(defaults.consts.SESSION_KEY));

    // Search bar callback
	const searchCallback = (searchData) => {
		props.setUsers(searchData);
	}

	// Return component
	return (

        <Fragment>

                {props.sloadingUsers &&
					<Skeleton
						width="100%"
						height="150px"
					/>
				}

				{!props.loadingUsers &&

					<Flex
						width="100%"
						minHeight="100px"
						overflowX="hidden"
						overflowY="auto"
						direction="column"
					>

						<SearchBar
							placeholder="Search users..."
							searchData={props.usersStatic}
							searchKeys={["name", "email"]}
							searchCallback={searchCallback}
						/>
				
						<Table.Root
							size="2"
							layout="auto"
						>

							<Table.Header>

								<Table.Row>
									<Table.ColumnHeaderCell>Name</Table.ColumnHeaderCell>
									<Table.ColumnHeaderCell>Email</Table.ColumnHeaderCell>
									<Table.ColumnHeaderCell>Last Login</Table.ColumnHeaderCell>

									{sessionData.user.permissions.includes("UsersFullAccess") &&
									
										<Table.ColumnHeaderCell>Permissions</Table.ColumnHeaderCell>

									}

									{sessionData.user.permissions.includes("UsersFullAccess") &&
										
										<Table.ColumnHeaderCell>Edit</Table.ColumnHeaderCell>

									}

								</Table.Row>

							</Table.Header>
						
							<Table.Body>

								{props.users.map((user, index) => (

									<Table.Row
										key={index}
									>

										<Table.RowHeaderCell>{user.name}</Table.RowHeaderCell>
										<Table.Cell>{user.email}</Table.Cell>
										<Table.Cell>{user.lastLogin.display}</Table.Cell>

										{sessionData.user.permissions.includes("UsersFullAccess") &&
									
											<Table.Cell>
												<span
													className="colour-dark-grey-hover cursor-pointer"
													onClick={() => { props.toggleViewPermissions(true, user); }}
												>View</span>
											</Table.Cell>

										}

										{sessionData.user.permissions.includes("UsersFullAccess") &&
											
											<Table.Cell>
												<span
													className="colour-dark-grey-hover cursor-pointer"
													onClick={() => { props.toggleEditUser(true, user); }}
												>Edit</span>
											</Table.Cell>

										}

									</Table.Row>


								))}

							</Table.Body>

						</Table.Root>

					</Flex>

				}

        </Fragment>

    )

};

// Props
ViewUsers.propTypes = {
	users: PropTypes.array.isRequired,
	usersStatic: PropTypes.array.isRequired,
	loadingUsers: PropTypes.bool.isRequired,
	setUsers: PropTypes.func.isRequired,
	toggleViewPermissions: PropTypes.func.isRequired,
	toggleEditUser: PropTypes.func.isRequired
};

// Export Component
export default ViewUsers;
