// Imports
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

// Components
import Modal from '../../../elements/Modal';
import InputHeading from '../../../inputs/InputHeading';
import TextInput from '../../../inputs/TextInput';
import PrimaryButton from '../../../buttons/PrimaryButton';
import { CheckboxCards, Flex, Text} from '@radix-ui/themes';

// Utils
import defaults from '../../../../utils/defaults';
import api from '@vikingevents/viking-fe-util-api';
import notification from '@vikingevents/viking-fe-util-notification';
import validate from '@vikingevents/viking-fe-util-validate';

// AddUsers component
const AddUsers = (props) => {

    // State
    const [addUserIsLoading, setAddUserIsLoading] = useState(false);
    const [addPermissionsCheckboxCards, setAddPermissionsCheckboxCards] = useState([]);

    // Refs
    const addUserNameInput = useRef(null);
	const addUseEmailInput = useRef(null);

    // Add permission checkbox card changed
	const addPermissionsCheckboxCardsChanged = (value) => {

		let values = addPermissionsCheckboxCards;
		if (addPermissionsCheckboxCards.includes(value)) {
			values = values.filter((item) => item !== value);
		} else {
			values.push(value);
		}

		setAddPermissionsCheckboxCards(values);

	}

    // Add User
	const addUser = () => {

		// Get values
		const name = addUserNameInput.current.value;
		const email = addUseEmailInput.current.value;

		// Validate
		if (!validate.fullName(name)) {
			notification.error("Please enter a valid name.");
			return;
		}

		if (!validate.email(email)) {
			notification.error("Please enter a valid email.");
			return;
		}

		if (email.split("@")[1] !== "vikingeventsgroup.com") {
			notification.error("Email address must be on the domain vikingeventsgroup.com.");
			return;
		}

		// Set loading
		setAddUserIsLoading(true);

		// Add user
		api.send({
			method: "POST",
			cacheKey: defaults.consts.CACHE_KEY,
			sessionKey: defaults.consts.SESSION_KEY,
			api: "viking_internal",
			endpoint: "/users/add",
			requiresAuth: true,
			data: {
				name: name,
				email: email,
                permissions: addPermissionsCheckboxCards
			}
		}).then((response) => {

			notification.success("User added successfully.");
			props.setUsers(response.data.users);
            props.setUsersStatic(response.data.users);
			setAddUserIsLoading(false);
            props.toggleAddUser(false);

		}).catch((error) => {

			setAddUserIsLoading(false);

		});


	};

	// Return component
	return (

        <Modal
            title="Add User"
            closeCallback={() => { props.toggleAddUser(false); }} 
        >

            <Flex
                width="100%"
                minHeight="100px"
                overflowX="hidden"
                overflowY="auto"
                direction="column"
                mt="20px"
            >

                <Flex
                    width="50%"
                    height="200px"
                    direction="column"
                    pr="5px"
                    pl="5px"
                    mb="20px"
                >

                    <InputHeading>Name:</InputHeading>

                    <TextInput
                        placeholder="Full Name"
                        type="text"
                        ref={addUserNameInput}
                    />

                    <InputHeading>Email:</InputHeading>

                    <TextInput
                        placeholder="email@vikingeventsgroup.com"
                        type="email"
                        ref={addUseEmailInput}
                    />

                </Flex>

                <Flex
                    width="100%"
                    pr="5px"
                    pl="5px"
                    mb="20px"
                    direction="column"
                >

                    <InputHeading>Permissions:</InputHeading>

                    <CheckboxCards.Root 
                        defaultValue={[]} 
                        columns={{ initial: "1", sm: "2" }}
                        color="orange"
                        mt="20px"
                        size="1"
                    >

                        {props.permissions.map((permission, index) => {

                            return(
                                                    
                                <CheckboxCards.Item 
                                    value={permission.name}
                                    key={index}
                                    color="orange"
                                    onClick={() => { addPermissionsCheckboxCardsChanged(permission.name); }}
                                >

                                    <Flex 
                                        direction="column" width="100%"
                                    >

                                        <Text>{permission.name}</Text>

                                    </Flex>

                                </CheckboxCards.Item>

                            );
                        
                        })}

                    </CheckboxCards.Root>

                </Flex>

                <Flex
                    width="50%"
                    mt="20px"
                    pl="5px"
                >

                    <PrimaryButton
                        onClick={() => { addUser(); }}
                        isLoading={addUserIsLoading}
                    >Add User</PrimaryButton>

                </Flex>

            </Flex>

        </Modal>

    )

};

// Props
AddUsers.propTypes = {
    toggleAddUser: PropTypes.func.isRequired,
    setUsers: PropTypes.func.isRequired,
    setUsersStatic: PropTypes.func.isRequired,
    permissions: PropTypes.array.isRequired,
};

// Export Component
export default AddUsers;
