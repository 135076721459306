// Imports
import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Flex } from '@radix-ui/themes';
import Meta from './../../Meta';

// Page component
const Page = (props) => {

	// Return component
	return (

		<Flex
			width="100%"
			minHeight="100px"
			overflowY="auto"
			overflowX="hidden"
			justify="start"
			align="center"
			direction="column"
			pt="40px"
			pl="20px"
		>

			<Meta 
                title={props.title}
            />

			{props.children}

		</Flex>

    )

};

// Props
Page.propTypes = {
    title: PropTypes.string.isRequired,
}

// Export Component
export default Page;
