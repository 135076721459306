// Imports
import React from 'react';

// Components
import Page from '../../../elements/Page';
import Section from '../../../elements/Section';

// ClientsManagePage component
const ClientsManagePage = () => {

	// Return component
	return (

		<Page 
			title="Clients - Manage"
		>

			<Section
				title="Clients - Manage"
			>

			</Section>

		</Page>

    )

};

// Export Component
export default ClientsManagePage;
