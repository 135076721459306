// Imports
import React, { useState, useEffect, useRef } from 'react';

// Components
import Page from '../../../elements/Page';
import Section from '../../../elements/Section';
import { Skeleton, Flex, Heading, Text, Badge } from '@radix-ui/themes'
import ContentInput from '../../../inputs/ContentInput';
import IconButton from '../../../buttons/IconButton';
import ContentViewer from '../../../elements/ContentViewer';
import AddTeamMember from '../../../sections/manageTeamPage/AddTeamMember';
import EditTeamMember from '../../../sections/manageTeamPage/EditTeamMember';
import SelectInput from '../../../inputs/SelectInput';
import Alert from '../../../feedback/Alert';

// Utils
import api from '@vikingevents/viking-fe-util-api';
import spinner from '@vikingevents/viking-fe-util-spinner';
import session from '@vikingevents/viking-fe-util-session';
import defaults from '../../../../utils/defaults';
import notification from '@vikingevents/viking-fe-util-notification';
import assets from '@vikingevents/viking-fe-util-assets';

// Icons
import { Pencil2Icon, TrashIcon } from '@radix-ui/react-icons';

// ManageTeamPage component
const ManageTeamPage = () => {

	// State
	const [sessionData, setSessionData] = useState(session.get(defaults.consts.SESSION_KEY));
	const [team, setTeam] = useState([]);
	const [teamIsLoading, setTeamIsLoading] = useState(true);
	const [memberInputVisible, setMemberInputVisible] = useState(false);
	const [editMemberVisible, setEditMemberVisible] = useState(false);
	const [selectedMember, setSelectedMember] = useState(null);

	// Sort team
	const sortTeam = (team) => {

		// Sort by order
		let sorted = team.sort((a, b) => {
			return Number(a.order) - Number(b.order);
		});

		return sorted;

	}

	// Get team
	useEffect(() => {

		const getTeam = async () => {

			spinner.show();

			await api.send({
				method: "GET",
				cacheKey: defaults.consts.CACHE_KEY,
				sessionKey: defaults.consts.SESSION_KEY,
				cacheableEndpoints: defaults.consts.CACHEABLE_ENDPOINTS,
				api: "viking_internal",
				endpoint: "/marketing/team",
				requiresAuth: true
			}).then((response) => {
				setTeam(sortTeam(response.data.team));
				spinner.hide();
				setTeamIsLoading(false);
			}).catch((error) => {
				console.log(error);
				spinner.hide();
			});

		};

		getTeam();

	}, []);

	// Toggle add team member
	const toggleAddTeamMember = (shouldShow) => {
		setMemberInputVisible(shouldShow);
	};

	// Handle delete 
	const handleDelete = async (member) => {

		spinner.show();

		await api.send({
			method: "POST",
			cacheKey: defaults.consts.CACHE_KEY,
			sessionKey: defaults.consts.SESSION_KEY,
			cacheableEndpoints: defaults.consts.CACHEABLE_ENDPOINTS,
			api: "viking_internal",
			endpoint: "/marketing/team/delete",
			requiresAuth: true,
			data: {
				member: member
			}
		}).then((response) => {
			setTeam(sortTeam(response.data.team));
			spinner.hide();
			notification.success("Team member deleted successfully.");
		}).catch((error) => {
			spinner.hide();
		});

	};

	// Toggle edit member
	const toggleEditMember = (shouldShow, member) => {
		setSelectedMember(member);
		setEditMemberVisible(shouldShow);
	}

	// Handle order change
	const handleOrderChange = async (value, params) => {

		console.log(value, params);

		spinner.show();

		await api.send({
			method: "POST",
			cacheKey: defaults.consts.CACHE_KEY,
			sessionKey: defaults.consts.SESSION_KEY,
			cacheableEndpoints: defaults.consts.CACHEABLE_ENDPOINTS,
			api: "viking_internal",
			endpoint: "/marketing/team/update/order",
			requiresAuth: true,
			data: {
				member: params[0],
				order: value
			}
		}).then((response) => {

			document.activeElement.blur();
			setTeam(sortTeam(response.data.team));
			spinner.hide();
			notification.success("Team member order updated successfully.");

		}).catch((error) => {
			spinner.hide

		});

	};

	// Return component
	return (

		<Page 
			title="Marketing - Manage Team"
		>

			<Section
				title="Marketing - Manage Team"
				addText="Add Team Member"
				onAddClick={sessionData.user.permissions.includes("MarketingTeamFullAccess") ? () => { toggleAddTeamMember(true); } : null}
			>

				{teamIsLoading &&
					<Skeleton
						width="100%"
						height="250px"
					>Loading</Skeleton>
				}

				{!teamIsLoading &&
					
					<Flex
						width="100%"
						minHeight="10px"
						overflowX="hidden"
						overflowY="auto"
						wrap="wrap"
						pb="40px"
					>

						{team.length === 0 &&
							<Text>No team members added yet.</Text>
						}

						{team.map((member, index) => {

							let orderOptions = [];
							for (let i=0; i<team.length; i++) {
								orderOptions.push({
									value: `${i + 1}`,
									label: `${i + 1}`
								});
							}
							orderOptions = orderOptions.sort((a, b) => {
								return Number(a.value) - Number(b.value);
							});
							

							return (
								<Flex
									width="480px"
									minHeight="10px"
									overflowX="hidden"
									overflowY="auto"
									key={index}
									className="background-very-light-grey"
									mr="20px"
									mt="20px"
									direction="column"
									position="relative"
								>

									<Flex
										justify="start"
										align="center"
										pt="20px"
									>

										<Flex
											width="160px"
											height="160px"
											overflow="hidden"
											justify="center"
											align="center"
											ml="20px"
										>
											<img 
												src={assets.getURL('viking', `${member.image.file}?w=320`)} 
												alt={member.name}
												className="img-full-square"
											/>
										</Flex>

										<Flex
											direction="column"
											p="10px"
											ml="20px"
										>

											<Text><strong>Name:</strong></Text>

											<Text>{member.name}</Text>

											<Text><strong>Title:</strong></Text>

											<Text>{member.title}</Text>

											<Text><strong>Email:</strong></Text>

											<Text>{member.email}</Text>

										</Flex>

									</Flex>

									<Flex
										direction="column"
										pl="20px"
										pr="20px"
										mt="20px"
									>

										<Text><strong>Bio:</strong></Text>

										<Text
											mt="10px"
										>{member.bio}</Text>

										<Text
											mt="10px"
										><strong>Hobbies:</strong></Text>

									</Flex>

									<Flex
										wrap="wrap"
										pl="20px"
										pr="20px"
										pt="10px"
									>

										{member.hobbies.map((hobby, index) => (
											<Badge 
												key={index} 
												color="orange"
												size="3"
												mr="5px"
												mt="5px"
											>{hobby}</Badge>
										))}

									</Flex>

									<Flex
										justify="between"
										align="center"
										pl="20px"
										pr="20px"
										mt="20px"
										pb="20px"
									>

										<Alert
											title="Delete Team Member"
											message="This will delete the team member from the website."
											confirmText="Delete"
											confirmAction={ () => { handleDelete(member); }}
										>

											<Flex
												width="24px"
												height="24px"
												overflow="hidden"
												align="center"
												justify="center"
												className="cursor-pointer background-danger-hover radius-3"
											>
												<TrashIcon
													className="colour-white"
													width="14px"
													height="14px"
												/>
											</Flex>

										</Alert>

										<IconButton
											icon={<Pencil2Icon />}
											onClick={() => { toggleEditMember(true, member); }}
											isSmall={true}
										/>

									</Flex>

									<Flex
										position="absolute"
										width="52px"
										height="42px"
										margin="auto"
										top="10px"
										right="10px"
										justify="end"
										align="center"
									>

										<SelectInput
											defaultValue={String(member.order)}
											onChange={handleOrderChange}
											options={orderOptions}
											noSelect={true}
											onChangeParams={[member]}
										/>

									</Flex>

								</Flex>
							)

					})}

					</Flex>

				}

			</Section>

			{memberInputVisible &&
				<AddTeamMember 
					toggleAddTeamMember={toggleAddTeamMember}
					setTeam={setTeam}
				/>
			}

			{editMemberVisible &&
				<EditTeamMember 
					toggleEditTeamMember={toggleEditMember}
					setTeam={setTeam}
					member={selectedMember}
				/>
			}

		</Page>

    )

};

// Export Component
export default ManageTeamPage;
