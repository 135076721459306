// Imports
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@radix-ui/themes'
import { MagnifyingGlassIcon } from '@radix-ui/react-icons';

// SearchBar component
const SearchBar = React.forwardRef((props, ref) => {

    // Refs
    const searchInput = useRef(null);

    // Search 
    const search = () => {

        let value = searchInput.current.value;
        let results = [];

        props.searchData.forEach((item) => {

            let hasBeenAdded = false;

            props.searchKeys.forEach((key) => {

                if (item[key].toLowerCase().includes(value.toLowerCase())) {

                    if (!hasBeenAdded) {
                        results.push(item);
                        hasBeenAdded = true;
                    }

                }

            });

        });

        props.searchCallback(results);

    }

	// Return component
	return (

        <TextField.Root 
            placeholder={props.placeholder}
            type="text"
            ref={searchInput}
            mt="10px"
            mb="20px"
            fontSize="14px"
            color="orange"
            onChange={() => { search(); }}
        >

            <TextField.Slot>
                <MagnifyingGlassIcon height="16" width="16" />
            </TextField.Slot>

        </TextField.Root>

    )

});

// Props
SearchBar.propTypes = {
    placeholder: PropTypes.string.isRequired,
    searchData: PropTypes.array.isRequired,
    searchKeys: PropTypes.array.isRequired,
    searchCallback: PropTypes.func.isRequired
};

// Export Component
export default SearchBar;
