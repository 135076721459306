// Imports
import React from 'react';
import PropTypes from 'prop-types';

// Hooks
import { useIsMobile } from '../../../hooks/useIsMobile';

// Components
import { Flex, Heading, Separator } from '@radix-ui/themes';
import IconButton from '../../buttons/IconButton';

// Icons 
import { PlusIcon } from '@radix-ui/react-icons';

// Section component
const Section = (props) => {

    // Is Mobile
    const isMobile = useIsMobile();

	// Return component
	return (

        <Flex
            width="100%"
            minHeight="100px"
            overflowX="hidden"
            overflowY="auto"
            justify="start"
            align="start"
            direction="column"
        >

            <Flex
                width="100%"
                height="50px"
                justify="start"
                align="start"
                direction="column"
                mb="40px"
            >

                <Flex
                    width="100%"
                    height="40px"
                    justify="between"
                    align="center"
                >

                    <Heading>{props.title}</Heading>

                    {props.onAddClick &&
                        <IconButton
                            icon={<PlusIcon />}
                            onClick={props.onAddClick}
                        >{props.addText}</IconButton>
                    }

                </Flex>

                <Separator 
                    size="3"
                    mt="10px"
                    className="background-primary"
                />

            </Flex>

            {props.children}

		</Flex>

    )

};

// Props
Section.propTypes = {
    title: PropTypes.string.isRequired,
    isPrimary: PropTypes.bool,
    isCentered: PropTypes.bool,
    noPaddingTop: PropTypes.bool,
    addText: PropTypes.string,
    onAddClick: PropTypes.func,
}

// Export Component
export default Section;
