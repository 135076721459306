// Imports
import React, { useState, useEffect, useRef, Fragment } from 'react';

// Components
import Page from '../../../elements/Page';
import Section from '../../../elements/Section';
import UploadMedia from '../../../sections/manageMediaPage/UploadMedia';
import { Skeleton, Flex, Text, Grid, Dialog, Button, TextField, Badge } from '@radix-ui/themes'
import IconButton from '../../../buttons/IconButton';
import Alert from '../../../feedback/Alert';

// Utils
import defaults from '../../../../utils/defaults';
import session from '@vikingevents/viking-fe-util-session';
import api from '@vikingevents/viking-fe-util-api';
import notification from '@vikingevents/viking-fe-util-notification';
import spinner from '@vikingevents/viking-fe-util-spinner';
import format from '@vikingevents/viking-fe-util-format';
import assets from '@vikingevents/viking-fe-util-assets';

// Icons
import { TrashIcon, Pencil1Icon, DownloadIcon } from '@radix-ui/react-icons';

// ManageMediaPage component
const ManageMediaPage = () => {

	// Refs
	const updateMediaNameInput = useRef(null);

	// State
	const [sessionData, setSessionData] = useState(session.get(defaults.consts.SESSION_KEY));
	const [media, setMedia] = useState([]);
	const [mediaFiltered, setMediaFiltered] = useState([]);
	const [mediaIsLoading, setMediaIsLoading] = useState(true);
	const [mediaTags, setMediaTags] = useState([]);
	const [mediaTagsYears, setMediaTagsYears] = useState([]);
	const [mediaTagsTypes, setMediaTagsTypes] = useState([
		{
			value: 't_image',
			label: 'Image'
		},
		{
			value: 't_video',
			label: 'Video'
		},
		{
			value: 't_audio',
			label: 'Audio'
		}
	]);
	const [uploadMediaIsVisible, setUploadMediaIsVisible] = useState(false);
	const [editingMediaItem, setEditingMediaItem] = useState(null);
	const [activeTags, setActiveTags] = useState([]);

	// Get media
	useEffect(() => {

		const getMedia = async () => { 

			spinner.show();

            await api.send({
                method: "GET",
                cacheKey: defaults.consts.CACHE_KEY,
                sessionKey: defaults.consts.SESSION_KEY,
				cacheableEndpoints: defaults.consts.CACHEABLE_ENDPOINTS,
                api: "viking_internal",
                endpoint: "/marketing/media",
                requiresAuth: true
            }).then((response) => {
                setMedia(response.data.media);
				let filtered = format.sortObjs(response.data.media, 'name');
				setMediaFiltered(filtered);
				setMediaTags(response.data.tags);
				setMediaIsLoading(false);
				spinner.hide();
            }).catch((error) => {
                notification.error("Error getting media.");
				console.log(error);
				spinner.hide();
            });

		}

		getMedia();

		let yearTags = [];
		let endYear = new Date().getFullYear();
		let currentYear = 2019;
		while (currentYear <= endYear) {
			yearTags.push({
				value: `y_${currentYear}`,
				label: currentYear.toString()
			});
			currentYear++;
		}
		setMediaTagsYears(yearTags);

	}, []);

	// Toggle upload media
	const toggleUploadMedia = (shouldOpen, responseData = null) => {

		if (responseData) {
			setMedia(responseData.media);
			setMediaTags(responseData.tags);
			checkFilteredMedia(null, responseData.media);
		}

		setUploadMediaIsVisible(shouldOpen);

	};

	// Handle edit
	const handleEdit = (mediaItem) => {
		setEditingMediaItem(mediaItem);
		setTimeout(() => {
			document.getElementById('editMediaDialogTrigger').click();
		}, 50);
	}

	// Handle delete
	const handleDelete = (mediaItem) => {
		
		spinner.show();

		api.send({
			method: "POST",
			cacheKey: defaults.consts.CACHE_KEY,
			sessionKey: defaults.consts.SESSION_KEY,
			api: "viking_internal",
			endpoint: "/marketing/media/delete",
			requiresAuth: true,
			data: {
				file: mediaItem
			}
		}).then((response) => {
			setMedia(response.data.media);
			setMediaTags(response.data.tags);
			spinner.hide();
			notification.success("Media deleted successfully.");
			checkFilteredMedia(null, response.data.media);
		}).catch((error) => {
			spinner.hide();
			notification.error("Error deleting media.");
		});

	}

	// Handle download
	const handleDownload = (mediaItem) => {
		window.open(assets.getURL('viking', `${mediaItem.folder}/${mediaItem.fileName}`, true), '_blank').focus();
	}

	// Handle update
	const handleUpdate = () => {

		let mediaItem = editingMediaItem;
		let newName = updateMediaNameInput.current.value;
		
		if (newName === '') {
			notification.error("Please enter a new file name.");
			return;
		}

		if (newName.includes('.')) {
			notification.error("File name cannot include a period.");
			return;
		}

		spinner.show();

		api.send({
			method: "POST",
			cacheKey: defaults.consts.CACHE_KEY,
			sessionKey: defaults.consts.SESSION_KEY,
			api: "viking_internal",
			endpoint: "/marketing/media/update",
			requiresAuth: true,
			data: {
				file: mediaItem,
				newName: newName
			}
		}).then((response) => {
			setMedia(response.data.media);
			setMediaTags(response.data.tags);
			spinner.hide();
			notification.success("Media updated successfully.");
			setEditingMediaItem(null);
			checkFilteredMedia(null, response.data.media);
		}).catch((error) => {
			spinner.hide();
			notification.error("Error updating media.");
		});

	}

	// Handle tag click
	const handleTagClick = (tag) => {

		let tags = activeTags;
		if (tags.includes(tag)) {
			tags = tags.filter((item) => item !== tag);
		} else {
			tags.push(tag);
		}

		setActiveTags([...tags]);

		checkFilteredMedia(tags, null);

	}

	// Check filtered media
	const checkFilteredMedia = (tags = null, newMedia = null) => {

		let useTags = activeTags;
		if (tags) {
			useTags = tags;
		}

		let useMedia = media;
		if (newMedia) {
			useMedia = newMedia;
		}

		if (useTags.length === 0) {
			let filtered = format.sortObjs(useMedia, 'name');
			setMediaFiltered([...filtered]);
			return;
		}

		let filtered = [];
		useMedia.forEach((mediaItem) => {
  
			let matchesCount = 0;
		  
			useTags.forEach((tag) => {
			  
				let isMatch = false;
			  
				mediaItem.tags.forEach((mediaTag) => {
				  if (mediaTag.value === tag) {
					isMatch = true;
				  }
				});
			  
				if (isMatch) {
				  matchesCount++;
				}
			  
			});
		  
			if (matchesCount === useTags.length) {
			  filtered.push(mediaItem);
			}
		  
		});

		filtered = format.sortObjs(filtered, 'name');

		setMediaFiltered([...filtered]);

	};

	// Return component
	return (

		<Page 
			title="Marketing - Manage Media"
		>

			<Section
				title="Marketing - Manage Media"
				addText="Upload Media"
				onAddClick={sessionData.user.permissions.includes("MarketingMediaFullAccess") ? () => { toggleUploadMedia(true); } : null}
			>

				{mediaIsLoading &&
					<Skeleton
						width="100%"
						height="250px"
					>Loading</Skeleton>
				}

				{!mediaIsLoading &&

					<Fragment>

						<Flex
							width="100%"
							minHeight="10px"
							overflowX="hidden"
							overflowY="auto"
							direction="column"
							mb="40px"
						>

							<Flex
								wrap="wrap"
								mb="20px"
							>

								{mediaTagsTypes.map((tag, index) => (
									<Badge
										key={index}
										size="2"
										color={activeTags.includes(tag.value) ? 'orange' : 'gray'}
										mr="10px"
										className="cursor-pointer"
										onClick={() => { handleTagClick(tag.value); }}
									>{tag.label}</Badge>
								))}

							</Flex>

							<Flex
								wrap="wrap"
								mb="20px"
							>

								{mediaTagsYears.map((tag, index) => (
									<Badge
										key={index}
										size="2"
										color={activeTags.includes(tag.value) ? 'orange' : 'gray'}
										mr="10px"
										className="cursor-pointer"
										onClick={() => { handleTagClick(tag.value); }}
									>{tag.label}</Badge>
								))}

							</Flex>

							<Flex
								wrap="wrap"
								mb="20px"
							>

								{mediaTags.map((tag, index) => (
									<Badge
										key={index}
										size="2"
										color={activeTags.includes(tag.value) ? 'orange' : 'gray'}
										mr="10px"
										mb="10px"
										className="cursor-pointer"
										onClick={() => { handleTagClick(tag.value); }}
									>{tag.label}</Badge>
								))}

							</Flex>

						</Flex>

						<Flex
							width="100%"
							justify="start"
							align="start"
							wrap="wrap"
						>

							{mediaFiltered.length === 0 &&
								<Text>No media found.</Text>
							}

							{mediaFiltered.length > 0 &&
							
								mediaFiltered.map((mediaItem, index) => {

									let dimensions = '';
									if (mediaItem.dimensions) {
										if (mediaItem.dimensions.width && mediaItem.dimensions.height) {
											dimensions = `${mediaItem.dimensions.width}px x ${mediaItem.dimensions.height}px`;
										}
									}

									return (

										<Flex
											width="250px"
											minHeight="10px"
											overflowX="hidden"
											overflowY="auto"
											className="background-very-light-grey radius-5"
											mr="20px"
											mb="20px"
											key={index}
											direction="column"
										>

											<Flex
												width="100%"
												height="140px"
												className="background-primary"
												align="center"
												justify="center"
												pl={mediaItem.type.includes('audio') ? "20px" : "0px"}
												pr={mediaItem.type.includes('audio') ? "20px" : "0px"}
												overflow="hidden"
											>

												{mediaItem.type.includes('image') &&
													<img 
														src={`${assets.getURL('viking', `${mediaItem.folder}/${mediaItem.fileName}`)}?w=500`} 
														alt={mediaItem.name} 
														className="img-full-square"
													/>
												}

												{mediaItem.type.includes('video') &&
													<video width="100%" height="100%" controls>
														<source src={assets.getURL('viking', `${mediaItem.folder}/${mediaItem.fileName}`)} type={mediaItem.type} />
													</video>
												}

												{mediaItem.type.includes('audio') &&
													<audio width="100%" height="100%" controls>
														<source src={assets.getURL('viking', `${mediaItem.folder}/${mediaItem.fileName}`)} type={mediaItem.type} />
													</audio>
												}

											</Flex>

											<Flex
												width="100%"
												p="10px"
												direction="column"
											>

												<Text
													className="font-size-14"
												>{mediaItem.name.split('.')[0]}</Text>

												<Text
													className="font-size-14 colour-medium-grey"
												>{dimensions}</Text>

												<Text
													className="font-size-14 colour-medium-grey"
												>{format.fileSize(mediaItem.size)}</Text>

											</Flex>

											<Flex
												width="100%"
												p="10px"
												justify={sessionData.user.permissions.includes("MarketingMediaFullAccess") ? "between" : "end"}
												align="center"
											>

												{sessionData.user.permissions.includes("MarketingMediaFullAccess") &&

												

													<Grid
														columns="2"
														gap="2"
													>

														<IconButton
															icon={<Pencil1Icon />}
															onClick={() => { handleEdit(mediaItem); }}
															isSmall
														></IconButton>

														<Alert
															title="Delete Media"
															message="This will completely remove the media from the system."
															confirmText="Delete"
															confirmAction={ () => { handleDelete(mediaItem); }}
														>

															<Flex
																width="24px"
																height="24px"
																overflow="hidden"
																align="center"
																justify="center"
																className="cursor-pointer background-danger-hover radius-3"
															>
																<TrashIcon
																	className="colour-white"
																	width="14px"
																	height="14px"
																/>
															</Flex>

														</Alert>

													</Grid>

												

												}

												<IconButton
													icon={<DownloadIcon />}
													onClick={() => { handleDownload(mediaItem); }}
													isSmall
												></IconButton>

											</Flex>

										</Flex>

									);

								})

							}

						</Flex>

					</Fragment>

				}

			</Section>

			{uploadMediaIsVisible && 
				<UploadMedia 
					toggleUploadMedia={toggleUploadMedia}
					mediaTags={mediaTags}
				/>
			}

			{editingMediaItem &&
			
				<Dialog.Root>

					<Dialog.Trigger>

						<span
							className="no-display"
							id="editMediaDialogTrigger"
						></span>

					</Dialog.Trigger>

					<Dialog.Content maxWidth="450px">

						<Dialog.Title>Edit Name</Dialog.Title>

						<Dialog.Description 
							size="2" 
							mb="4">Change the file name.
						</Dialog.Description>

						<Flex 
							direction="column" 
							gap="3"
						>

							<label>

								<Text 
									as="div" 
									size="2" 
									mb="1"
									weight="bold"
								>
									File Name
								</Text>

								<TextField.Root
									defaultValue={editingMediaItem.name.split('.')[0]}
									placeholder="Enter file name"
									color="orange"
									ref={updateMediaNameInput}
								/>

							</label>

						</Flex>

						<Flex gap="3" mt="4" justify="end">

							<Dialog.Close>

								<Button 
									variant="soft" 
									color="gray"
									onClick={() => { setEditingMediaItem(null); }}
								>Cancel
								</Button>

							</Dialog.Close>

							<Dialog.Close>
								<Button 
									color="orange"
									onClick={() => { handleUpdate(); }}
								>Save</Button>
							</Dialog.Close>

						</Flex>

					</Dialog.Content>

				</Dialog.Root>

			}

		</Page>

    )

};

// Export Component
export default ManageMediaPage;
