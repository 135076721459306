// Imports
import React, { useState, useRef, useEffect } from 'react';
import JSONPretty from 'react-json-pretty';

// Components
import Page from '../../../elements/Page';
import Section from '../../../elements/Section';
import Modal from '../../../elements/Modal';
import InputHeading from '../../../inputs/InputHeading';
import TextInput from '../../../inputs/TextInput';
import PrimaryButton from '../../../buttons/PrimaryButton';
import TextAreaInput from '../../../inputs/TextAreaInput';
import Alert from '../../../feedback/Alert';
import { Skeleton, Table, Flex, Tooltip, Text, Code } from '@radix-ui/themes';
import SearchBar from '../../../inputs/SearchBar';

// Utils
import defaults from '../../../../utils/defaults';
import api from '@vikingevents/viking-fe-util-api';
import notification from '@vikingevents/viking-fe-util-notification';
import spinner from '@vikingevents/viking-fe-util-spinner';
import format from '@vikingevents/viking-fe-util-format';

// UserActionsPage component
const UserActionsPage = () => {

	// State
	const [actionsLoading, setActionsLoading] = useState(true);
	const [actions, setActions] = useState([]);
    const [requestIsVisible, setRequestIsVisible] = useState(false);
    const [selectedAction, setSelectedAction] = useState(null);

	// Get actions
	useEffect(() => { 

		const getActions = async () => {

			spinner.show();

			api.send({
                method: "GET",
                cacheKey: defaults.consts.CACHE_KEY,
                sessionKey: defaults.consts.SESSION_KEY,
				cacheableEndpoints: defaults.consts.CACHEABLE_ENDPOINTS,
                api: "viking_internal",
                endpoint: "/actions",
				requiresAuth: true
            }).then((response) => {

                spinner.hide();
				notification.success("Actions loaded successfully.");

				setActions(format.sortObjs(response.data.actions, "timestamp", true));
				setActionsLoading(false);

            }).catch((error) => {
                
                spinner.hide();

            });
		}

		getActions();

	}, []);

    // Toggle view request
    const toggleViewRequest = (shouldShow, action = null) => {

        if (action !== null) {
            setSelectedAction(action);
        }

        setRequestIsVisible(shouldShow);

    }

	// Return component
	return (

		<Page 
			title="Settings - User Actions"
		>

			<Section
				title="Settings - User Actions"
			>

				{actionsLoading &&
					<Skeleton
						width="100%"
						height="150px"
					/>
				}

				{!actionsLoading &&

					<Flex
						width="100%"
						minHeight="100px"
						overflowX="hidden"
						overflowY="auto"
						direction="column"
					>

                            <Table.Root
                                size="2"
                                layout="auto"
                            >
    
                                <Table.Header>
    
                                    <Table.Row>
                                        <Table.ColumnHeaderCell>Name</Table.ColumnHeaderCell>
                                        <Table.ColumnHeaderCell>User</Table.ColumnHeaderCell>
                                        <Table.ColumnHeaderCell>Location</Table.ColumnHeaderCell>
                                        <Table.ColumnHeaderCell>Request</Table.ColumnHeaderCell>
                                        <Table.ColumnHeaderCell>Timestamp</Table.ColumnHeaderCell>
                                    </Table.Row>
    
                                </Table.Header>
                            
                                <Table.Body>
    
                                    {actions.map((action, index) => {
    
                                        return (

                                            <Table.Row
                                                key={index}
                                            >

                                                <Table.RowHeaderCell>
                                                    <Tooltip content={action.action.description}>
                                                        <Text>{action.action.name}</Text>
                                                    </Tooltip> 
                                                </Table.RowHeaderCell>
                                                <Table.Cell>{action.user}</Table.Cell>
                                                <Table.Cell>{action.requestLocation}</Table.Cell>
                                                <Table.Cell
                                                    onClick={ () => { toggleViewRequest(true, action) } }
                                                >Request</Table.Cell>
                                                <Table.Cell>{action.timestamp}</Table.Cell>
            
                                            </Table.Row>

                                        );
    
                                    })}
    
                                </Table.Body>
    
                            </Table.Root>
						
					</Flex>

				}

			</Section>

            {requestIsVisible &&
            
                <Modal
                    title="Request Details"
                    closeCallback={ () => { toggleViewRequest(false) } }
                >

                    <Flex
                        width="100%"
                        minHeight="10px"
                        overflowX="hidden"
                        overflowY="auto"
                        direction="column"
                    >

                        <Text><strong>ID:</strong> {selectedAction._id.toString()}</Text>
                        <Text><strong>Name:</strong> {selectedAction.name}</Text>
                        <Text><strong>User:</strong> {selectedAction.user}</Text>
                        <Text><strong>Location:</strong> {selectedAction.requestLocation}</Text>
                        <Text><strong>Timestamp:</strong> {selectedAction.timestamp}</Text>
                        <Text><strong>Request:</strong></Text>
                        <JSONPretty themeClassName="json-pretty-custom" id="json-pretty" data={selectedAction.request}></JSONPretty>

                    </Flex>

                </Modal>
            
            }

		</Page>

    )

};

// Export Component
export default UserActionsPage;
