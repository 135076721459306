// Imports
import React from 'react';

// Components
import Page from '../../../elements/Page';
import Section from '../../../elements/Section';

// RequestConsumablesPage component
const RequestConsumablesPage = () => {

	// Return component
	return (

		<Page 
			title="Assets - Request Consumables"
		>

			<Section
				title="Assets Request Consumables"
			>

			</Section>

		</Page>

    )

};

// Export Component
export default RequestConsumablesPage;
