// Imports
import React, { useState, useEffect } from 'react';

// Components
import Page from '../../../elements/Page';
import Section from '../../../elements/Section';
import { Skeleton, Flex, Heading, Text, Badge } from '@radix-ui/themes'
import ContentInput from '../../../inputs/ContentInput';
import IconButton from '../../../buttons/IconButton';
import ContentViewer from '../../../elements/ContentViewer';

// Utils
import api from '@vikingevents/viking-fe-util-api';
import spinner from '@vikingevents/viking-fe-util-spinner';
import session from '@vikingevents/viking-fe-util-session';
import defaults from '../../../../utils/defaults';
import notification from '@vikingevents/viking-fe-util-notification';

// Icons
import { FileIcon, EyeOpenIcon, Cross1Icon } from '@radix-ui/react-icons';

// ManageNewsPage component
const ManageNewsPage = () => {

	// State
	const [sessionData, setSessionData] = useState(session.get(defaults.consts.SESSION_KEY));
	const [news, setNews] = useState([]);
	const [newsIsLoading, setNewsIsLoading] = useState(true);
	const [contentInputVisible, setContentInputVisible] = useState(false);
	const [previewVisible, setPreviewVisible] = useState(false);
	const [selectedArticle, setSelectedArticle] = useState(null);
	const [contentEditVisible, setContentEditVisible] = useState(false);

	// Get gallery 
	useEffect(() => { 

		const getNews = async () => {

			spinner.show();

			await api.send({
				method: "GET",
				cacheKey: defaults.consts.CACHE_KEY,
				sessionKey: defaults.consts.SESSION_KEY,
				cacheableEndpoints: defaults.consts.CACHEABLE_ENDPOINTS,
				api: "viking_internal",
				endpoint: "/marketing/news",
				requiresAuth: true
			}).then((response) => {
				setNews(response.data.news);
				spinner.hide();
				setNewsIsLoading(false);
			}).catch((error) => {
				spinner.hide();
			});


		};

		getNews();

	}, []);

	// Toggle add news
	const toggleAddNews = (shouldOpen) => {
		setContentInputVisible(shouldOpen);
	};

	// Toggle preview
	const togglePreview = (shouldOpen, article = null) => {
		setSelectedArticle(article);
		setPreviewVisible(shouldOpen);
	};	

	// Toggle edit content
	const toggleEditContent = (shouldOpen, data = null) => {
		setSelectedArticle(data);
		setContentEditVisible(shouldOpen);
	};

	// Handle add content save
	const handleAddContentSave = (content, mediaItems, isDraft) => {

		spinner.show();

		api.send({
			method: "POST",
			cacheKey: defaults.consts.CACHE_KEY,
			sessionKey: defaults.consts.SESSION_KEY,
			api: "viking_internal",
			endpoint: "/marketing/news/upload",
			requiresAuth: true,
			data: {
				content: content,
				media: mediaItems,
				isDraft: isDraft
			}
		}).then((response) => {
			setNews(response.data.news);
			notification.success("News saved successfully");
			toggleAddNews(false);
			spinner.hide();
		}).catch((error) => {
			spinner.hide();
		});

	};

	// Handle edit content save
	const handleEditContentSave = (content, mediaItems, isDraft, article) => {
		
		spinner.show();

		api.send({
			method: "POST",
			cacheKey: defaults.consts.CACHE_KEY,
			sessionKey: defaults.consts.SESSION_KEY,
			api: "viking_internal",
			endpoint: "/marketing/news/update",
			requiresAuth: true,
			data: {
				content: content,
				media: mediaItems,
				isDraft: isDraft,
				article: article
			}
		}).then((response) => {
			setNews(response.data.news);
			notification.success("News saved successfully");
			toggleEditContent(false);
			spinner.hide();
		}).catch((error) => {
			spinner.hide();
		});

	}

	// Handle delete content
	const handleDeleteContent = (article) => {

		spinner.show();

		api.send({
			method: "POST",
			cacheKey: defaults.consts.CACHE_KEY,
			sessionKey: defaults.consts.SESSION_KEY,
			api: "viking_internal",
			endpoint: "/marketing/news/delete",
			requiresAuth: true,
			data: {
				article: article
			}
		}).then((response) => {
			setNews(response.data.news);
			notification.success("News deleted successfully");
			toggleEditContent(false);
			spinner.hide();
		}).catch((error) => {
			spinner.hide();
		});

	};

	// Return component
	return (

		<Page 
			title="Marketing - Manage News"
		>

			<Section
				title="Marketing - Manage News"
				addText="Add News"
				onAddClick={sessionData.user.permissions.includes("MarketingNewsFullAccess") ? () => { toggleAddNews(true); } : null}
			>

				{newsIsLoading &&
					<Skeleton
						width="100%"
						height="250px"
					>Loading</Skeleton>
				}

				{!newsIsLoading &&

					<Flex
						width="100%"
						minHeight="10px"
						overflowX="hidden"
						overflowY="auto"
						justify="start"
						align="start"
						direction="column"
					>

						{news.map((newsItem, index) => {

							let title = newsItem.content[0].content[0];
							let content = "";
							newsItem.content.forEach((contentItem, index) => {
								if (contentItem.tag === "p" && content === "") {
									content = contentItem.content;
								}
							});
							content = String(content).substring(0, 480) + "...";

							return (

								<Flex
									width="100%"
									minHeight="10px"
									overflowX="hidden"
									overflowY="auto"
									key={index}
									className="background-very-light-grey radius-3"
									p="20px"
									mb="20px"
									justify="start"
									align="start"
									direction="column"
								>

									<Heading>{title}</Heading>

									<Text
										mt="10px"
										className="font-size-14 colour-medium-grey"
									>Last Updated: {newsItem.updatedAt.display}</Text>
									<Text
										className="font-size-14 colour-medium-grey"
									>Written By: {newsItem.writtenBy}</Text>

									<Text
										mt="10px"
										mb="20px"
									>{content}</Text>

									<Flex
										width="100%"
										height="50px"
										justify="between"
										align="center"
									>

										<Flex
											width="200px"
											height="50px"
											justify="start"
											align="center"
										>
											{newsItem.isDraft ?

												<Badge
													color="gray"
													size="3"
												>Draft</Badge>

												:

												<Badge
													color="orange"
													size="3"
												>Published</Badge>

											}
										</Flex>	
										
										<Flex
											width="160px"
											height="50px"
											justify="between"
											align="center"
										>

											<IconButton
												icon={<FileIcon />}
												onClick={() => { toggleEditContent(true, newsItem); }}
												isSecondary
											>Edit</IconButton>

											<IconButton
												icon={<EyeOpenIcon />}
												onClick={() => { togglePreview(true, newsItem); }}
											>View</IconButton>

										</Flex>

									</Flex>									

								</Flex>

							);

						})}

					</Flex>

				}

			</Section>

			{contentInputVisible &&
				<ContentInput 
					closeCallback={toggleAddNews}
					addContentSaveCallback={handleAddContentSave}
				/>
			}

			{contentEditVisible &&
				<ContentInput 
					closeCallback={toggleEditContent}
					content={selectedArticle.content}
					media={selectedArticle.media}
					article={selectedArticle}
					isEdit={true}
					editContentSaveCallback={handleEditContentSave}
					deleteContentCallback={handleDeleteContent}
				/>
			}

			{previewVisible &&
			
				<Flex
					width="100%"
					height="100vh"
					className="z-index-1000 background-dark-grey-trans"
					position="fixed"
					margin="auto"
					top="0"
					left="0"
					right="0"
					bottom="0"
					justify="center"
					align="center"
				>

					<Flex
						width="880px"
						height="800px"
						overflow="hidden"
					>

						<ContentViewer 
							content={selectedArticle.content}
							media={selectedArticle.media}
						/>

					</Flex>

					<Flex
						width="32px"
						height="32px"
						position="absolute"
						className="background-primary radius-3 cursor-pointer"
						margin="auto"
						top="20px"
						right="20px"
						justify="center"
						align="center"
						onClick={() => { togglePreview(false); }}
					>
						<Cross1Icon 
							className="colour-white"
						/>
						
					</Flex>

				</Flex>

			}

		</Page>

    )

};

// Export Component
export default ManageNewsPage;
