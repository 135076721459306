// Imports
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// Components
import { Flex, Heading, Separator, Skeleton, Badge, Text } from '@radix-ui/themes';
import IconButton from '../../buttons/IconButton';
import TextAreaInput from '../TextAreaInput';
import MediaPicker from '../MediaPicker';
import InputHeading from '../InputHeading';
import TagInput from '../TagInput';
import PrimaryButton from '../../buttons/PrimaryButton';

// Icons
import { Cross1Icon, CheckIcon, MixerHorizontalIcon, PlusCircledIcon, CropIcon, ClockIcon } from '@radix-ui/react-icons';

// Utils
import api from '@vikingevents/viking-fe-util-api';
import notification from '@vikingevents/viking-fe-util-notification';
import defaults from '../../../utils/defaults';
import spinner from '@vikingevents/viking-fe-util-spinner';
import assets from '@vikingevents/viking-fe-util-assets';
import format from '@vikingevents/viking-fe-util-format';

// Assets
import FacebookLight from '../../../assets/social/facebook-light.png';
import FacebookDark from '../../../assets/social/facebook-dark.png';
import InstagramLight from '../../../assets/social/instagram-light.png';
import InstagramDark from '../../../assets/social/instagram-dark.png';
import TikTokLight from '../../../assets/social/tiktok-light.png';
import TikTokDark from '../../../assets/social/tiktok-dark.png';
import { Icon } from '@radix-ui/themes/dist/esm/components/callout.js';

// SocialPostInput component
const SocialPostInput = React.forwardRef((props, ref) => {

    // Refs
    const contentRef = useRef(null);

    // State
    const [facebookIsSelected, setFacebookIsSelected] = useState(false);
    const [instagramIsSelected, setInstagramIsSelected] = useState(false);
    const [tikTokIsSelected, setTikTokIsSelected] = useState(false);
    const [allowedMediaTypes, setAllowedMediaTypes] = useState("image, video");
    const [hashtags, setHashtags] = useState([]);
    const [scheduleFor, setScheduleFor] = useState("Now");
    const [mediaSelected, setMediaSelected] = useState([
        {
            "_id": "67707d2014e2b8aa4098a159",
            "name": "Crew.png",
            "size": 2514652,
            "type": "image/png",
            "tags": [
                {
                    "value": "t_image",
                    "label": "Image"
                },
                {
                    "value": "y_2024",
                    "label": "2024"
                },
                {
                    "value": "d_SocialPost",
                    "label": "SocialPost"
                }
            ],
            "dimensions": {
                "width": 2160,
                "height": 2160
            },
            "fileName": "tH34s1Sk2o2o552W72928.png",
            "folder": "marketing/media"
        },
        {
            "_id": "67707df514e2b8aa4098a164",
            "name": "ethan.png",
            "size": 13503014,
            "type": "image/png",
            "tags": [
                {
                    "value": "t_image",
                    "label": "Image"
                },
                {
                    "value": "y_2024",
                    "label": "2024"
                },
                {
                    "value": "d_Team",
                    "label": "Team"
                }
            ],
            "dimensions": {
                "width": 2916,
                "height": 2916
            },
            "fileName": "cg4N32l04yq1v47257550.png",
            "folder": "marketing/media"
        },
        {
            "_id": "67707d2014e2b8aa4098a15a",
            "name": "Event Design.png",
            "size": 1786394,
            "type": "image/png",
            "tags": [
                {
                    "value": "t_image",
                    "label": "Image"
                },
                {
                    "value": "y_2024",
                    "label": "2024"
                },
                {
                    "value": "d_SocialPost",
                    "label": "SocialPost"
                }
            ],
            "dimensions": {
                "width": 2160,
                "height": 2160
            },
            "fileName": "159a2V357429xR2Gr822e.png",
            "folder": "marketing/media"
        },
        {
            "_id": "6770784714e2b8aa4098a090",
            "name": "gallery-14.png",
            "size": 2812892,
            "type": "image/png",
            "tags": [
                {
                    "value": "t_image",
                    "label": "Image"
                },
                {
                    "value": "y_2022",
                    "label": "2022"
                },
                {
                    "value": "d_Barrier",
                    "label": "Barrier"
                },
                {
                    "value": "d_Telehandler",
                    "label": "Telehandler"
                },
                {
                    "value": "d_Crew",
                    "label": "Crew"
                }
            ],
            "dimensions": {
                "width": 2400,
                "height": 1080
            },
            "fileName": "W338L47mk2c0140v1Y758.png",
            "folder": "marketing/media"
        },
        {
            "_id": "6770784714e2b8aa4098a093",
            "name": "gallery-11.png",
            "size": 5154141,
            "type": "image/png",
            "tags": [
                {
                    "value": "t_image",
                    "label": "Image"
                },
                {
                    "value": "y_2023",
                    "label": "2023"
                },
                {
                    "value": "d_Crew",
                    "label": "Crew"
                },
                {
                    "value": "d_Fence",
                    "label": "Fence"
                }
            ],
            "dimensions": {
                "width": 2048,
                "height": 1536
            },
            "fileName": "4D1p3e74D8m100D8Ry253.png",
            "folder": "marketing/media"
        },
        {
            "_id": "6770784714e2b8aa4098a094",
            "name": "gallery-10.png",
            "size": 5204312,
            "type": "image/png",
            "tags": [
                {
                    "value": "t_image",
                    "label": "Image"
                },
                {
                    "value": "y_2022",
                    "label": "2022"
                },
                {
                    "value": "d_Stage",
                    "label": "Stage"
                },
                {
                    "value": "d_PitBarrier",
                    "label": "PitBarrier"
                },
                {
                    "value": "d_HeritageLive",
                    "label": "HeritageLive"
                }
            ],
            "dimensions": {
                "width": 2400,
                "height": 2250
            },
            "fileName": "741Wa8C355140W23fSy08.png",
            "folder": "marketing/media"
        },
        {
            "_id": "6770784714e2b8aa4098a09d",
            "name": "gallery-1.png",
            "size": 4108060,
            "type": "image/png",
            "tags": [
                {
                    "value": "t_image",
                    "label": "Image"
                },
                {
                    "value": "y_2022",
                    "label": "2022"
                },
                {
                    "value": "d_BoundaryBrighton",
                    "label": "BoundaryBrighton"
                },
                {
                    "value": "d_Show",
                    "label": "Show"
                },
                {
                    "value": "d_Stage",
                    "label": "Stage"
                }
            ],
            "dimensions": {
                "width": 2400,
                "height": 1800
            },
            "fileName": "i10831np4L4J873J5270u.png",
            "folder": "marketing/media"
        },
        {
            "_id": "677a7eae8700ec7ec1c9a015",
            "name": "AutoCAD - Design Suite.mp4",
            "size": 89952585,
            "type": "video/mp4",
            "tags": [
                {
                    "value": "t_video",
                    "label": "Video"
                },
                {
                    "value": "y_2019",
                    "label": "2019"
                },
                {
                    "value": "d_CAD",
                    "label": "CAD"
                },
                {
                    "value": "d_DesignSuite",
                    "label": "DesignSuite"
                }
            ],
            "dimensions": {
                "width": null,
                "height": null
            },
            "fileName": "8D6q3Z701H109h7I57068.mp4",
            "uploadedAt": "2025-01-05T12:44:28.771Z",
            "uploadedBy": "64ab2d6481bbfea9a772f1c6",
            "folder": "marketing/media"
        }
    ]);
    const [mediaPickerIsVisible, setMediaPickerIsVisible] = useState(false);

    // Handle platform selected
    const handlePlatformSelected = (platform) => {

        if (platform === "facebook") {
            setFacebookIsSelected(!facebookIsSelected);
            if (!tikTokIsSelected) {
                setAllowedMediaTypes("image, video");
            }
            return;
        }

        if (platform === "instagram") {
            setInstagramIsSelected(!instagramIsSelected);
            if (!tikTokIsSelected) {
                setAllowedMediaTypes("image, video");
            }
            return;
        }

        if (platform === "tiktok") {
            setTikTokIsSelected(!tikTokIsSelected);
            setAllowedMediaTypes("video");
            return;
        }

    }
    
    // Toggle media picker
    const toggleMediaPicker = (shouldShow) => { 
        setMediaPickerIsVisible(shouldShow);
    }

    // Handle media selected
    const handleMediaSelected = (media) => {
        
        console.log(mediaSelected);
        setMediaSelected([...mediaSelected, ...media]);
        toggleMediaPicker(false);

    }

    // Handle remove media
    const handleRemoveMedia = (media, index) => {
        let updatedMedia = mediaSelected;
        updatedMedia.splice(index, 1);
        setMediaSelected([...updatedMedia]);
    }

    // Handle crop media
    const handleCropMedia = (media, index) => {
        console.log("crop", media); // TODO:
    }

    // Handle schedule clicked
    const handleScheduleClicked = () => {
        console.log("schedule"); // TODO: 
    }

    // Handle Post
    const handlePost = () => {

        // Get values
        let facebook = facebookIsSelected;
        let instagram = instagramIsSelected;
        let tiktok = tikTokIsSelected;
        let content = contentRef.current.value;
        let tags = hashtags;
        let media = mediaSelected;

        console.log("post"); // TODO:
    }

	// Return component
	return (

        <Flex
            width="1200px"
            height="860px"
            className="position-center-fixed z-index-1000 background-white shadow-6 radius-10"
            overflowX="hidden"
            overflowY="auto"
            direction="column"
            justify="flex-start"
            align="flex-start"
            p="40px"
        >

            <Flex
                width="100%"
                height="50px"
                justify="start"
                align="start"
                direction="column"
            >

                <Flex
                    width="100%"
                    height="40px"
                    justify="between"
                    align="start"
                >

                    <Heading>New Social Post</Heading>

                    <IconButton
                        icon={<Cross1Icon />}
                        onClick={props.closeCallback}
                    ></IconButton>

                </Flex>

                <Separator 
                    size="3"
                    mt="10px"
                    className="background-primary"
                />

            </Flex>

            <Flex
                width="100%"
                minHeight="10px"
                overflowX="hidden"
                overflowY="auto"
                mt="20px"
                direction="column"
            >

                <InputHeading>Select Platform:</InputHeading>

                <Flex
                    mt="20px"
                    mb="20px"
                >

                    <Flex
                        width="130px"
                        height="38px"
                        className={facebookIsSelected ? "background-primary border-solid-primary-dark radius-5 cursor-pointer" : "background-very-light-grey border-solid-grey radius-5 cursor-pointer"}
                        justify="between"
                        align="center"
                        overflow="hidden"
                        pl="20px"
                        pr="20px"
                        mr="20px"
                        onClick={() => { handlePlatformSelected("facebook"); }}
                    >
                        <img src={facebookIsSelected ? FacebookLight : FacebookDark} width="20px" />
                        <Text
                            className={facebookIsSelected ? "colour-white font-size-14" : "colour-dark-grey font-size-14"}
                        >Facebook</Text>
                    </Flex>

                    <Flex
                        width="130px"
                        height="38px"
                        className={instagramIsSelected ? "background-primary border-solid-primary-dark radius-5 cursor-pointer" : "background-very-light-grey border-solid-grey radius-5 cursor-pointer"}
                        justify="between"
                        align="center"
                        overflow="hidden"
                        pl="20px"
                        pr="20px"
                        mr="20px"
                        onClick={() => { handlePlatformSelected("instagram"); }}
                    >
                        <img src={instagramIsSelected ? InstagramLight : InstagramDark} width="20px" />
                        <Text
                            className={instagramIsSelected ? "colour-white font-size-14" : "colour-dark-grey font-size-14"}
                        >Instagram</Text>
                    </Flex>

                    <Flex
                        width="130px"
                        height="38px"
                        className={tikTokIsSelected ? "background-primary border-solid-primary-dark radius-5 cursor-pointer" : "background-very-light-grey border-solid-grey radius-5 cursor-pointer"}
                        justify="between"
                        align="center"
                        overflow="hidden"
                        pl="20px"
                        pr="20px"
                        onClick={() => { handlePlatformSelected("tiktok"); }}
                    >
                        <img src={tikTokIsSelected ? TikTokLight : TikTokDark} width="20px" />
                        <Text
                            className={tikTokIsSelected ? "colour-white font-size-14" : "colour-dark-grey font-size-14"}
                        >TikTok</Text>
                    </Flex>

                </Flex>

                <InputHeading>Content:</InputHeading>

                <TextAreaInput 
                    placeholder="Enter your post content here..."
                    ref={contentRef}
                />

                <TagInput 
                    setTags={setHashtags}
                    tags={hashtags}
                    placeholderText="Enter your hashtags here..."
                    title="Hashtags"
                />

                <Flex
                    width="80px"
                    justify="between"
                    align="center"
                >

                    <InputHeading>Media:</InputHeading>

                    <PlusCircledIcon
                        color="orange"
                        onClick={() => { toggleMediaPicker(true); }}
                        className="cursor-pointer"
                        width="18px"
                        height="18px"
                    />

                </Flex>

                <Flex
                    width="100%"
                    height="160px"
                    overflowX="scroll"
                    overflowY="auto"
                    mt="10px"
                >

                    {mediaSelected.length === 0 &&
                        <Text>No media selected.</Text>
                    }

                    {mediaSelected.map((media, index) => {

                        return (
                            <Flex
                                width="160px"
                                height="160px"
                                className="background-very-light-grey radius-5"
                                mr="20px"
                                key={index}
                                flexShrink="0"
                                overflow="hidden"
                                align="center"
                                justify="center"
                                position="relative"
                            >

                                {media.type.includes("image") &&
                                    <img 
                                        src={assets.getURL('viking', `${media.folder}/${media.fileName}?w=320`)}
                                        className={media.dimensions.width > media.dimensions.height ? "img-full-width" : "img-full-height"}
                                    />
                                }

                                {media.type.includes("video") &&
                                    <video 
                                        src={assets.getURL('viking', `${media.folder}/${media.fileName}`)} 
                                        controls
                                        height="160px"
                                        width="160px"
                                    ></video>
                                }

                                <Flex
                                    width="20px"
                                    height="20px"
                                    position="absolute"
                                    className="background-danger-hover radius-3 cursor-pointer"
                                    margin="auto"
                                    top="10px"
                                    right="10px"
                                    justify="center"
                                    align="center"
                                    onClick={() => { handleRemoveMedia(media, index); }}
                                >
                                    <Cross1Icon
                                        color="white"
                                    />
                                </Flex>

                                {media.type.includes("image") &&

                                    <Flex
                                        width="20px"
                                        height="20px"
                                        position="absolute"
                                        className="background-primary-hover radius-3 cursor-pointer"
                                        margin="auto"
                                        top="10px"
                                        left="10px"
                                        justify="center"
                                        align="center"
                                        onClick={() => { handleCropMedia(media, index); }}
                                    >
                                        <CropIcon
                                            color="white"
                                        />
                                    </Flex>

                                }

                            </Flex>
                        )

                    })}

                </Flex>

                <Flex
                    justify="end"
                    align="center"
                    mt="40px"
                >

                    <IconButton
                        icon={<ClockIcon />}
                        onClick={() => { handleScheduleClicked(); }}
                        isSecondary
                        addMarginRight
                    >{`Schedule For: ${scheduleFor}`}</IconButton>

                    <PrimaryButton
                        onClick={() => { handlePost(); }}
                    >Post</PrimaryButton>

                </Flex>

            </Flex>

            {mediaPickerIsVisible &&
            
                <MediaPicker 
                    closeCallback={() => { toggleMediaPicker(false); }}
                    allowMultiple={true}
                    saveCallback={handleMediaSelected}
                    allowedTypes={allowedMediaTypes}
                />

            }

        </Flex>

    )

});

// Props
SocialPostInput.propTypes = {
    closeCallback: PropTypes.func.isRequired,
};

// Export Component
export default SocialPostInput;