// Imports
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

// Components
import Modal from '../../../elements/Modal';
import InputHeading from '../../../inputs/InputHeading';
import TextInput from '../../../inputs/TextInput';
import TextAreaInput from '../../../inputs/TextAreaInput';
import TagInput from '../../../inputs/TagInput';
import PrimaryButton from '../../../buttons/PrimaryButton';
import MediaPicker from '../../../inputs/MediaPicker';
import { CheckboxCards, Flex, Text, TextArea} from '@radix-ui/themes';
import IconButton from '../../../buttons/IconButton';

// Utils
import defaults from '../../../../utils/defaults';
import api from '@vikingevents/viking-fe-util-api';
import notification from '@vikingevents/viking-fe-util-notification';
import validate from '@vikingevents/viking-fe-util-validate';
import spinner from '@vikingevents/viking-fe-util-spinner';

// Icons
import { CameraIcon } from '@radix-ui/react-icons';
import { set } from 'store';

// AddTeamMember component
const AddTeamMember = (props) => {

    // State
    const [addTeamMemberIsLoading, setAddTeamMemberIsLoading] = useState(false);
    const [hobbies, setHobbies] = useState([]);
    const [mediaPickerIsVisible, setMediaPickerIsVisible] = useState(false);
    const [chosenImage, setChosenImage] = useState({name: ""});

    // Refs
    const nameInput = useRef(null);
	const titleInput = useRef(null);
    const emailInput = useRef(null);
    const bioInput = useRef(null);

    // Add Team Member
	const addTeamMember = () => {

		// Get values
        let name = nameInput.current.value;
        let title = titleInput.current.value;
        let email = emailInput.current.value;
        let bio = bioInput.current.value;
        let image = chosenImage;

        // Validate
        if (!validate.string(name)) {
            notification.error("Name is required");
            return;
        }

        if (!validate.string(title)) {
            notification.error("Title is required");
            return;
        }

        if (!validate.string(email)) {
            notification.error("Email is required");
            return;
        }

        if (!validate.string(bio)) {
            notification.error("Bio is required");
            return;
        }

        if (hobbies.length === 0) {
            notification.error("At least one hobby is required");
            return;
        }

        if (image.name === "") {
            notification.error("Image is required");
            return;
        }

        spinner.show();
        setAddTeamMemberIsLoading(true);

        api.send({
            method: "POST",
            cacheKey: defaults.consts.CACHE_KEY,
            sessionKey: defaults.consts.SESSION_KEY,
            cacheableEndpoints: defaults.consts.CACHEABLE_ENDPOINTS,
            api: "viking_internal",
            endpoint: "/marketing/team/add",
            requiresAuth: true,
            data: {
                member: {
                    name: name,
                    title: title,
                    email: email,
                    bio: bio,
                    image: image,
                    hobbies: hobbies
                }
            }
        }).then((response) => {

            spinner.hide();
            setAddTeamMemberIsLoading(false);

            props.setTeam(response.data.team);
            notification.success("Team member added successfully");
            props.toggleAddTeamMember(false);

        }).catch((error) => {
            spinner.hide();
            setAddTeamMemberIsLoading(false);
        });


	};

    // Const toggle media picker
    const toggleMediaPicker = (shouldShow) => {
        setMediaPickerIsVisible(shouldShow);
    };

    // Handle media save
    const handleMediaSave = (media) => {
        setChosenImage(media[0]);
        toggleMediaPicker(false);
    };

	// Return component
	return (

        <Modal
            title="Add Team Member"
            closeCallback={() => { props.toggleAddTeamMember(false); }} 
            isTall={true}
        >

            <Flex
                width="100%"
                minHeight="100px"
                overflowX="hidden"
                overflowY="auto"
                direction="column"
                mt="20px"
            >

                <Flex
                    width="100%"
                    minHeight="100px"
                    overflowX="hidden"
                    overflowY="auto"
                    pr="5px"
                    pl="5px"
                    justify="between"
                    align="start"
                >

                    <Flex
                        width="48%"
                        minHeight="100px"
                        overflowX="hidden"
                        overflowY="auto"
                        direction="column"
                    >

                        <InputHeading>Name:</InputHeading>

                        <TextInput
                            placeholder="name"
                            type="text"
                            ref={nameInput}
                        />

                        <InputHeading>Title:</InputHeading>

                        <TextInput
                            placeholder="title"
                            type="text"
                            ref={titleInput}
                        />

                        <InputHeading>Email:</InputHeading>

                        <TextInput
                            placeholder="email"
                            type="email"
                            ref={emailInput}
                        />

                    </Flex>

                    <Flex
                        width="48%"
                        minHeight="100px"
                        overflowX="hidden"
                        overflowY="auto"
                        direction="column"
                    >


                        <InputHeading>Bio:</InputHeading>

                        <TextAreaInput 
                            placeholder="bio"
                            ref={bioInput}
                        />

                    </Flex>

                </Flex>

                <Flex
                    mt="20px"
                    pl="5px"
                    direction="column"
                >

                    <TagInput 
                        setTags={setHobbies}
                        tags={hobbies}
                        placeholderText="Add a hobby..."
                        title="Hobbies"
                    />

                </Flex>

                <Flex
                    width="150px"
                    height="80px"
                    mt="20px"
                    pl="5px"
                    direction="column"
                    justify="between"
                >

                    <InputHeading>Image:</InputHeading>

                    <IconButton
                        icon={<CameraIcon />}
                        onClick={() => { toggleMediaPicker(true); }}
                        isSecondary={true}
                    >Select Image</IconButton>

                </Flex>

                <Flex
                    width="150px"
                    mt="10px"
                    pl="5px"
                    direction="column"
                >

                    <Text
                        className="font-size-14"
                    >Selected Image: {chosenImage.name}</Text>

                </Flex>

                <Flex
                    width="50%"
                    mt="40px"
                    pl="5px"
                >

                    <PrimaryButton
                        onClick={() => { addTeamMember(); }}
                        isLoading={addTeamMemberIsLoading}
                    >Add Team Member</PrimaryButton>

                </Flex>

            </Flex>

            {mediaPickerIsVisible &&
                <MediaPicker 
                    closeCallback={() => { toggleMediaPicker(false); }}
                    saveCallback={handleMediaSave}
                    allowedTypes={"image"}
                />
            }

        </Modal>

    )

};

// Props
AddTeamMember.propTypes = {
    toggleAddTeamMember: PropTypes.func.isRequired,
    setTeam: PropTypes.func.isRequired,
};

// Export Component
export default AddTeamMember;
