// Imports
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import { Flex, Heading, Separator } from '@radix-ui/themes';
import IconButton from '../../buttons/IconButton';

// Icons
import { Cross1Icon } from '@radix-ui/react-icons';

// Modal component
const Modal = (props) => {

    // On component mount
    useEffect(() => {

        // Add no-scroll class to body
        document.body.classList.add('no-scroll');

    }, []);

    // On component unmount
    useEffect(() => {

        // Remove no-scroll class from body
        return () => {
            document.body.classList.remove('no-scroll');
        };

    }, []);

	return (

        <Flex
            width="800px"
            height={props.isTall ? "800px" : "650px"}
            className="position-center-fixed z-index-1000 background-white shadow-6 radius-10"
            overflowX="hidden"
            overflowY="auto"
            direction="column"
            justify="flex-start"
            align="flex-start"
            p="40px"
        >

            <Flex
                width="100%"
                height="50px"
                justify="start"
                align="start"
                direction="column"
            >

                <Flex
                    width="100%"
                    height="40px"
                    justify="between"
                    align="start"
                >

                    <Heading>{props.title}</Heading>

                    <IconButton
                        icon={<Cross1Icon />}
                        onClick={props.closeCallback}
                    ></IconButton>

                </Flex>

                <Separator 
                    size="3"
                    mt="10px"
                    className="background-primary"
                />

            </Flex>
            
            <Flex
                width="100%"
                minHeight="10px"
                overflowY="auto"
                overflowX="hidden"
                justify="start"
                align="start"
                direction="column"
                mt="10px"
            >

                {props.children}

            </Flex>

        </Flex>

    )

};

// PropTypes
Modal.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    closeCallback: PropTypes.func.isRequired,
    isTall: PropTypes.bool
};

// Export Component
export default Modal;