// Imports
import React from 'react';
import PropTypes from 'prop-types';
import { TextArea } from '@radix-ui/themes'

// TextAreaInput component
const TextAreaInput = React.forwardRef((props, ref) => {

	// Return component
	return (

        <TextArea 
            placeholder={props.placeholder}
            ref={ref}
            mt="10px"
            mb="20px"
            fontSize="14px"
            disabled={props.disabled}
            color="orange"
        />

    )

});

// Props
TextAreaInput.propTypes = {
    placeholder: PropTypes.string.isRequired,
    disabled: PropTypes.bool
};

// Export Component
export default TextAreaInput;
