// Imports
import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Flex } from '@radix-ui/themes';

// Assets
import { CheckCircledIcon, CrossCircledIcon } from '@radix-ui/react-icons';

// PasswordStrengthCard component
const PasswordStrengthCard = (props) => {

    const iconSize = "18px";

    // Return component
    return (

        <Flex
            width="100%"
            minHeight="10px"
            overflowY="auto"
            overflowX="hidden"
            justify="start"
            align="start"
            direction="column"
        >

            <ul>
                <li>
                    {
                        props.has8Chars ? 
                        <CheckCircledIcon 
                            width={iconSize} 
                            height={iconSize} 
                            color="green" 
                        />
                        : 
                        <CrossCircledIcon 
                            width={iconSize}
                            height={iconSize}
                        />
                    }
                    <span>At least 8 characters long.</span>
                </li>
                <li>
                {
                        props.hasNumber ? 
                        <CheckCircledIcon 
                            width={iconSize} 
                            height={iconSize} 
                            color="green" 
                        />
                        : 
                        <CrossCircledIcon 
                            width={iconSize}
                            height={iconSize}
                        />
                    }
                    <span>Contains at least 1 number.</span>
                </li>
                <li>
                {
                        props.hasUppercase ? 
                        <CheckCircledIcon 
                            width={iconSize} 
                            height={iconSize} 
                            color="green" 
                        />
                        : 
                        <CrossCircledIcon 
                            width={iconSize}
                            height={iconSize}
                        />
                    }
                    <span>Contains at least 1 uppercase letter.</span>
                </li>
                <li>
                {
                        props.hasLowercase ? 
                        <CheckCircledIcon 
                            width={iconSize} 
                            height={iconSize} 
                            color="green" 
                        />
                        : 
                        <CrossCircledIcon 
                            width={iconSize}
                            height={iconSize}
                        />
                    }
                    <span>Contains at least 1 lowercase letter.</span>
                </li>
                <li>
                {
                        props.hasSpecial ? 
                        <CheckCircledIcon 
                            width={iconSize} 
                            height={iconSize} 
                            color="green" 
                        />
                        : 
                        <CrossCircledIcon 
                            width={iconSize}
                            height={iconSize}
                        />
                    }
                    <span>Contains at least 1 special character.</span>
                </li>
            </ul>

        </Flex>

    )

};

// Prop Types
PasswordStrengthCard.propTypes = {
    has8Chars: PropTypes.bool.isRequired,
    hasNumber: PropTypes.bool.isRequired,
    hasUppercase: PropTypes.bool.isRequired,
    hasLowercase: PropTypes.bool.isRequired,
    hasSpecial: PropTypes.bool.isRequired,
};

// Export Component
export default PasswordStrengthCard;
