// Imports
import React, { useState, useRef, useEffect } from 'react';

// Components
import Page from '../../../elements/Page';
import Section from '../../../elements/Section';
import Modal from '../../../elements/Modal';
import InputHeading from '../../../inputs/InputHeading';
import TextInput from '../../../inputs/TextInput';
import PrimaryButton from '../../../buttons/PrimaryButton';
import TextAreaInput from '../../../inputs/TextAreaInput';
import Alert from '../../../feedback/Alert';
import { Skeleton, Table, Flex } from '@radix-ui/themes';
import SearchBar from '../../../inputs/SearchBar';

// Utils
import defaults from '../../../../utils/defaults';
import api from '@vikingevents/viking-fe-util-api';
import notification from '@vikingevents/viking-fe-util-notification';
import spinner from '@vikingevents/viking-fe-util-spinner';
import validate from '@vikingevents/viking-fe-util-validate';
import session from '@vikingevents/viking-fe-util-session';

// Icons
import { TrashIcon } from '@radix-ui/react-icons';

// ManagePermissionsPage component
const ManagePermissionsPage = () => {

	// Refs 
	const addPermissionNameInput = useRef(null);
	const addPermissionDescriptionInput = useRef(null);

	// State
	const [permissionsLoading, setPermissionsLoading] = useState(true);
	const [permissions, setPermissions] = useState([]);
	const [permissionsStatic, setPermissionsStatic] = useState([]);
	const [addPermissionIsLoading, setAddPermissionIsLoading] = useState(false);
	const [addPermissionIsVisible, setAddPermissionIsVisible] = useState(false);
	const [sessionData, setSessionData] = useState(session.get(defaults.consts.SESSION_KEY));

	// Get permissions
	useEffect(() => { 

		const getPermissions = async () => {

			spinner.show();

			api.send({
                method: "GET",
                cacheKey: defaults.consts.CACHE_KEY,
                sessionKey: defaults.consts.SESSION_KEY,
				cacheableEndpoints: defaults.consts.CACHEABLE_ENDPOINTS,
                api: "viking_internal",
                endpoint: "/permissions",
				requiresAuth: true
            }).then((response) => {

                spinner.hide();
				notification.success("Permissions loaded successfully.");

				setPermissions(response.data.permissions);
				setPermissionsStatic(response.data.permissions);
				setPermissionsLoading(false);

            }).catch((error) => {
                
                spinner.hide();

            });
		}

		getPermissions();

	}, []);

	// Show add permission
	const toggleAddPermission = (shouldOpen) => {
		setAddPermissionIsVisible(shouldOpen);
	};

	// Add permission
	const addPermission = () => {

		// Get values
		const name = addPermissionNameInput.current.value;
		const description = addPermissionDescriptionInput.current.value;

		// Validate
		if (!validate.string(name)) {
			notification.error("Please enter a name.");
			return;
		}

		if (!validate.string(description)) {
			notification.error("Please enter a description.");
			return;
		}

		// Set loading
		setAddPermissionIsLoading(true);

		// Add user
		api.send({
			method: "POST",
			cacheKey: defaults.consts.CACHE_KEY,
			sessionKey: defaults.consts.SESSION_KEY,
			api: "viking_internal",
			endpoint: "/permissions/add",
			requiresAuth: true,
			data: {
				name: name,
				description: description
			}
		}).then((response) => {

			notification.success("Permission added successfully.");
			setPermissions(response.data.permissions);
			setAddPermissionIsLoading(false);
			setAddPermissionIsVisible(false);

		}).catch((error) => {

			setAddPermissionIsLoading(false);

		});

	}

	// Delete permission
	const deletePermission = (permission) => {
		
		spinner.show();

		api.send({
			method: "POST",
			cacheKey: defaults.consts.CACHE_KEY,
			sessionKey: defaults.consts.SESSION_KEY,
			api: "viking_internal",
			endpoint: "/permissions/delete",
			requiresAuth: true,
			data: {
				id: permission._id,
				name: permission.name
			}
		}).then((response) => {

			notification.success("Permission deleted successfully.");
			setPermissions(response.data.permissions);
			spinner.hide();

		}).catch((error) => {

			spinner.hide();

		});

	}

	// Search bar callback
	const searchCallback = (searchData) => {
		setPermissions(searchData);
	}

	// Return component
	return (

		<Page 
			title="Settings - Manage Permissions"
		>

			<Section
				title="Settings - Manage Permissions"
				addText="Add Permission"
				onAddClick={sessionData.user.permissions.includes("PermissionsFullAccess") ? () => {  toggleAddPermission(true); } : null}
			>

				{permissionsLoading &&
					<Skeleton
						width="100%"
						height="150px"
					/>
				}

				{!permissionsLoading &&

					<Flex
						width="100%"
						minHeight="100px"
						overflowX="hidden"
						overflowY="auto"
						direction="column"
					>

						<SearchBar 
							placeholder="Search permissions..."
							searchData={permissionsStatic}
							searchKeys={["name", "description"]}
							searchCallback={searchCallback}
						/>

						<Table.Root
							size="2"
							layout="auto"
						>

							<Table.Header>

								<Table.Row>
									<Table.ColumnHeaderCell>ID</Table.ColumnHeaderCell>
									<Table.ColumnHeaderCell>Name</Table.ColumnHeaderCell>
									<Table.ColumnHeaderCell>Description</Table.ColumnHeaderCell>

									{sessionData.user.permissions.includes("PermissionsFullAccess") && 
									
										<Table.ColumnHeaderCell
											align="center"
										>Delete</Table.ColumnHeaderCell>

									}

								</Table.Row>

							</Table.Header>
						
							<Table.Body>

								{permissions.map((permission, index) => (

									<Table.Row
										key={index}
									>

										<Table.RowHeaderCell>{permission._id}</Table.RowHeaderCell>
										<Table.Cell>{permission.name}</Table.Cell>
										<Table.Cell>{permission.description}</Table.Cell>

										{sessionData.user.permissions.includes("PermissionsFullAccess") && 
									
											<Table.Cell
												align="center"
											>
												
												<Alert
													title="Delete Permission"
													message="This will stop all users with this permission from accessing the respective features."
													confirmText="Delete"
													confirmAction={ () => { deletePermission(permission); }}
												>

													<TrashIcon 
														className="cursor-pointer colour-danger-hover"
														width="20px"
														height="20px"
														
													/>

												</Alert>
												
											</Table.Cell>

										}

									</Table.Row>


								))}

							</Table.Body>

						</Table.Root>

					</Flex>

				}

			</Section>

			{addPermissionIsVisible &&
			
				<Modal
					title="Add Permission"
					closeCallback={() => { toggleAddPermission(false); }}
				>

					<Flex
						width="100%"
						minHeight="100px"
						overflowX="hidden"
						overflowY="auto"
						direction="column"
						mt="20px"
					>

						<Flex
							width="50%"
							minHeight="100px"
							overflowX="hidden"
							overflowY="auto"
							direction="column"
							pr="5px"
							pl="5px"
						>

							<InputHeading>Name (CamelCase):</InputHeading>

							<TextInput
								placeholder="PermissionName"
								type="text"
								ref={addPermissionNameInput}
							/>

							<InputHeading>Description:</InputHeading>

							<TextAreaInput
								placeholder="Permission description..." 
								ref={addPermissionDescriptionInput}
							/>

						</Flex>

						<Flex
							width="50%"
							mt="20px"
						>

							<PrimaryButton
								onClick={() => { addPermission(); }}
								isLoading={addPermissionIsLoading}
							>Add Permission</PrimaryButton>

						</Flex>

					</Flex>

				</Modal>
			
			}

		</Page>

    )

};

// Export Component
export default ManagePermissionsPage;
