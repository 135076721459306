// Imports
import React from 'react';

// Components
import Page from '../../../elements/Page';
import Section from '../../../elements/Section';

// MaintenancePage component
const MaintenancePage = () => {

	// Return component
	return (

		<Page 
			title="Assets - Maintenance"
		>

			<Section
				title="Assets - Maintenance"
			>

			</Section>

		</Page>

    )

};

// Export Component
export default MaintenancePage;
