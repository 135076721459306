// Imports
import React, { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';

// Components
import { Flex, Text } from '@radix-ui/themes';

// Icons
import { ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons';

// Utils
import defaults from '../../../utils/defaults';
import session from '@vikingevents/viking-fe-util-session';

// SideNav component
const SideNav = () => {

    // Nav items
    const [navItems, setNavItems] = useState(buildNavItems());

    // Toggle section
    const toggleSection = (sectionIndex) => {

        // Get current
        let current = navItems[sectionIndex];

        // Update
        current.isOpen = !current.isOpen;

        // Update state
        setNavItems([
            ...navItems.slice(0, sectionIndex),
            current,
            ...navItems.slice(sectionIndex + 1)
        ]);
    }

	// Return component
	return (

		<Flex
            width="300px"
            minHeight="300px"
            overflowX="hidden"
            overflowY="hidden"
            justify="start"
            align="center"
            direction="column"
            pt="40px"
        >

            <Flex
                className="background-dark-grey"
                width="260px"
                minHeight="100px"
                overflowX="hidden"
                overflowY="auto"
                justify="start"
                align="start"
                direction="column"
                pt="20px"
                pb="20px"
            >

                {navItems.map((navItem, sectionIndex) => (

                    <Fragment key={sectionIndex}>

                        <Flex
                            width="100%"
                            height="40px"
                            justify="between"
                            align="center"
                            pl="20px"
                            pr="20px"
                            className="cursor-pointer"
                            onClick={ () => { toggleSection(sectionIndex) } }
                        >

                            <Text
                                className="colour-white font-weight-medium font-size-16"
                            >{navItem.title}</Text>

                            <Text
                                className="colour-white"
                            >
                                {navItem.isOpen ? 
                                    <ChevronUpIcon /> 
                                : 
                                    <ChevronDownIcon />
                                }
                            </Text>

                        </Flex>

                        <Flex
                            className="background-primary"
                            width="100%"
                            minHeight="0px"
                            maxHeight={navItem.isOpen ? "1000px" : "0px"}
                            overflowX="hidden"
                            overflowY="auto"
                            justify="start"
                            align="start"
                            direction="column"
                            pt={navItem.isOpen ? "20px" : "0px"}
                        >

                            {navItem.items.map((item, itemIndex) => (
                              
                                <Flex
                                    key={itemIndex}
                                    width="100%"
                                    height="40px"
                                    pl="20px"
                                    pr="20px"
                                >
                                    <Link
                                        to={item.link}
                                        className="colour-white-no-hover font-size-14"
                                    >{item.title}</Link>
                                </Flex>

                            ))}

                        </Flex>

                    </Fragment>

                ))}

            </Flex>

        </Flex>

    )

};

// Build nav items
const buildNavItems = () => {

    let sessionData = session.get(defaults.consts.SESSION_KEY);
    let navItems = [];

    // Build sections
    navItems.push({
        title: "Clients",
        isOpen: false,
        items: []
    });

    navItems.push({
        title: "Work",
        isOpen: false,
        items: []
    });

    navItems.push({
        title: "Assets",
        isOpen: false,
        items: []
    });

    navItems.push({
        title: "Marketing",
        isOpen: false,
        items: []
    });

    navItems.push({
        title: "Settings",
        isOpen: false,
        items: []
    });

    /*
     * Clients
    */ 
    navItems[0].items.push({
        title: "View Clients",
        link: "/clients"
    });

    navItems[0].items.push({
        title: "Manage Clients",
        link: "/clients/manage"
    });

    if (navItems[0].items.length === 0) {
        navItems.splice(0, 1);
    }

    /*
     * Work
    */
    navItems[1].items.push({
        title: "Projects",
        link: "/projects"
    });

    navItems[1].items.push({
        title: "Hires",
        link: "/hires"
    });

    navItems[1].items.push({
        title: "Quotes",
        link: "/quotes"
    });

    navItems[1].items.push({
        title: "Invoices",
        link: "/invoices"
    });

    if (navItems[1].items.length === 0) {
        navItems.splice(1, 1);
    }

    /*
     * Assets
    */
    navItems[2].items.push({
        title: "Stock",
        link: "/assets/stock"
    });

    navItems[2].items.push({
        title: "Trailers",
        link: "/assets/trailers"
    });

    navItems[2].items.push({
        title: "Containers",
        link: "/assets/containers"
    });

    navItems[2].items.push({
        title: "Vehicles",
        link: "/assets/vehicles"
    });

    navItems[2].items.push({
        title: "Equipment",
        link: "/assets/equipment"
    });

    navItems[2].items.push({
        title: "Maintenance",
        link: "/assets/maintenance"
    });

    navItems[2].items.push({
        title: "Request Consumables",
        link: "/assets/consumables"
    });

    navItems[2].items.push({
        title: "Report Issue",
        link: "/assets/report-issue"
    });

    if (navItems[2].items.length === 0) {
        navItems.splice(2, 1);
    }

    /*
     * Marketing
    */

    if (sessionData.user.permissions.includes('MarketingGalleryFullAccess')) {
    
        navItems[3].items.push({
            title: "Manage Gallery",
            link: "/marketing/gallery"
        });

    }

    if (sessionData.user.permissions.includes('MarketingNewsFullAccess')) {

        navItems[3].items.push({
            title: "Manage News",
            link: "/marketing/news"
        });

    }

    if (sessionData.user.permissions.includes('MarketingRecentProjectsFullAccess')) {

        navItems[3].items.push({
            title: "Manage Recent Projects",
            link: "/marketing/recent-projects"
        });

    }

    if (sessionData.user.permissions.includes('MarketingTeamFullAccess')) {

        navItems[3].items.push({
            title: "Manage Team",
            link: "/marketing/team"
        });

    }

    if (sessionData.user.permissions.includes('MarketingMediaFullAccess') || sessionData.user.permissions.includes('MarketingMediaReadOnly')) {
    
        navItems[3].items.push({
            title: "Manage Media",
            link: "/marketing/media"
        });

    }

    if (sessionData.user.permissions.includes('MarketingSocialScheduleFullAccess') || sessionData.user.permissions.includes('MarketingSocialScheduleReadOnly')) {
    
        navItems[3].items.push({
            title: "Social Scheduler",
            link: "/marketing/social/schedule"
        });

    }

    if (sessionData.user.permissions.includes('MarketingSocialAnalyticsFullAccess')) {
    
        navItems[3].items.push({
            title: "Social Analytics",
            link: "/marketing/social/analytics"
        });

    }

    if (navItems[3].items.length === 0) {
        navItems.splice(3, 1);
    }

    /*
     * Settings
    */
    if (sessionData.user.permissions.includes('UsersFullAccess') || sessionData.user.permissions.includes('UsersReadOnly')) {

        navItems[4].items.push({
            title: "Manage Users",
            link: "/settings/users"
        });

    }

    if (sessionData.user.permissions.includes('PermissionsFullAccess') || sessionData.user.permissions.includes('PermissionsReadOnly')) {

        navItems[4].items.push({
            title: "Manage Permissions",
            link: "/settings/permissions"
        });

    }

    if (sessionData.user.permissions.includes('UserActionsFullAccess')) {

        navItems[4].items.push({
            title: "User Actions",
            link: "/settings/actions"
        });

    }

    if (navItems[4].items.length === 0) {
        navItems.splice(4, 1);
    }

    // Return
    return navItems;

}

// Export Component
export default SideNav;
