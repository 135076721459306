// Imports
import React, { useState, useEffect, useRef } from 'react';

// Components
import Page from '../../../elements/Page';
import Section from '../../../elements/Section';
import { Skeleton, Flex, Heading, Text, Badge } from '@radix-ui/themes'
import ContentInput from '../../../inputs/ContentInput';
import IconButton from '../../../buttons/IconButton';
import ContentViewer from '../../../elements/ContentViewer';
import AddTeamMember from '../../../sections/manageTeamPage/AddTeamMember';
import EditTeamMember from '../../../sections/manageTeamPage/EditTeamMember';
import SelectInput from '../../../inputs/SelectInput';
import Alert from '../../../feedback/Alert';
import SocialPostInput from '../../../inputs/SocialPostInput';

// Utils
import api from '@vikingevents/viking-fe-util-api';
import spinner from '@vikingevents/viking-fe-util-spinner';
import session from '@vikingevents/viking-fe-util-session';
import defaults from '../../../../utils/defaults';
import notification from '@vikingevents/viking-fe-util-notification';
import assets from '@vikingevents/viking-fe-util-assets';

// Icons
import { Pencil2Icon, TrashIcon } from '@radix-ui/react-icons';

// SocialSchedulerPage component
const SocialSchedulerPage = () => {

    // State
    const [sessionData, setSessionData] = useState(session.get(defaults.consts.SESSION_KEY));
    const [socialPostInputVisible, setSocialPostInputVisible] = useState(false);

    // Toggle schedule post
    const toggleSchedulePost = (shouldShow) => {
        setSocialPostInputVisible(shouldShow);
    }

	// Return component
	return (

		<Page 
			title="Marketing - Social Schedule"
		>

			<Section
				title="Marketing - Social Schedule"
                addText="Schedule Post"
				onAddClick={sessionData.user.permissions.includes("MarketingSocialScheduleFullAccess") ? () => { toggleSchedulePost(true); } : null}
			>

			</Section>

            {socialPostInputVisible && 

                <SocialPostInput 
                    closeCallback={() => { toggleSchedulePost(false); }}
                />

            }

		</Page>

    )

};

// Export Component
export default SocialSchedulerPage;
