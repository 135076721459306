import { useEffect, useState } from "react";
import { useClientWidth } from "./useClientWidth";
import defaults from "../utils/defaults";

export const useIsMobile = () => {
  
    const clientWidth = useClientWidth();
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        if (clientWidth < defaults.consts.MOBILE_WIDTH) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [clientWidth]);

    return isMobile;

};