// Imports
import React from 'react';

// Components
import Page from '../../../elements/Page';
import Section from '../../../elements/Section';

// InvoicesPage component
const InvoicesPage = () => {

	// Return component
	return (

		<Page 
			title="Invoices"
		>

			<Section
				title="Invoices"
			>

			</Section>

		</Page>

    )

};

// Export Component
export default InvoicesPage;
