// Imports
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import { Flex, Heading, Separator, Skeleton, Badge, Text } from '@radix-ui/themes';
import IconButton from '../../buttons/IconButton';

// Icons
import { Cross1Icon, CheckIcon, MixerHorizontalIcon } from '@radix-ui/react-icons';

// Utils
import api from '@vikingevents/viking-fe-util-api';
import notification from '@vikingevents/viking-fe-util-notification';
import defaults from '../../../utils/defaults';
import spinner from '@vikingevents/viking-fe-util-spinner';
import assets from '@vikingevents/viking-fe-util-assets';
import format from '@vikingevents/viking-fe-util-format';

// MediaPicker component
const MediaPicker = React.forwardRef((props, ref) => {

    // State
    const [isLoading, setIsLoading] = useState(true);
    const [media, setMedia] = useState([]);
    const [mediaFiltered, setMediaFiltered] = useState([]);
    const [tags, setTags] = useState([]);
    const [tagsYears, setTagsYears] = useState([]);
	const [tagsTypes, setTagsTypes] = useState([
		{
			value: 't_image',
			label: 'Image'
		},
		{
			value: 't_video',
			label: 'Video'
		},
		{
			value: 't_audio',
			label: 'Audio'
		}
	]);
    const [selectedMedia, setSelectedMedia] = useState(props.initiallySelected || []);
    const [activeTags, setActiveTags] = useState([]);
    const [filtersIsOpen, setFiltersIsOpen] = useState(false);
    
    // Get media
    useEffect(() => {

        const getMedia = async () => {

            setIsLoading(true);
            spinner.show();

            // Get media
            await api.send({
                method: "GET",
                cacheKey: defaults.consts.CACHE_KEY,
                sessionKey: defaults.consts.SESSION_KEY,
                cacheableEndpoints: defaults.consts.CACHEABLE_ENDPOINTS,
                api: "viking_internal",
                endpoint: "/marketing/media",
                requiresAuth: true
            }).then((response) => {
                let filtered = format.sortObjs(response.data.media, 'name');
                setMedia(response.data.media);
                setMediaFiltered(filtered);
                setTags(response.data.tags);
                setIsLoading(false);
                spinner.hide();
            }).catch((error) => {
                notification.error("Error getting media.");
                setIsLoading(false);
                spinner.hide();
            });


        };

        getMedia();

        let yearTags = [];
		let endYear = new Date().getFullYear();
		let currentYear = 2019;
		while (currentYear <= endYear) {
			yearTags.push({
				value: `y_${currentYear}`,
				label: currentYear.toString()
			});
			currentYear++;
		}
		setTagsYears(yearTags);

    }, []);

    // Handle media select 
    const handleMediaSelect = (mediaItem) => {

        if (props.allowedTypes) {

            if (mediaItem.type.includes('image') && !props.allowedTypes.includes('image')) {
                notification.error("Images are not allowed.");
                return;
            }

            if (mediaItem.type.includes('video') && !props.allowedTypes.includes('video')) {
                notification.error("Videos are not allowed.");
                return;
            }

            if (mediaItem.type.includes('audio') && !props.allowedTypes.includes('audio')) {
                notification.error("Audio files are not allowed.");
                return;
            }

        }

        let updatedMedia = selectedMedia;

        if (props.allowMultiple) {

            let searchIndex = format.findIndex(updatedMedia, '_id', mediaItem._id);

            if (searchIndex === -1) {
                updatedMedia.push(mediaItem);
            } else {
                updatedMedia.splice(searchIndex, 1);
            }

        } else {

            updatedMedia = [mediaItem];

        }

        setSelectedMedia([...updatedMedia]);

    };

    // Handle tag click
    const handleTagClick = (tag) => {
        
        let tags = activeTags;
		if (tags.includes(tag)) {
			tags = tags.filter((item) => item !== tag);
		} else {
			tags.push(tag);
		}

		setActiveTags([...tags]);

		checkFilteredMedia(tags, null);

    };

    // Check filtered media
	const checkFilteredMedia = (tags = null, newMedia = null) => {

		let useTags = activeTags;
		if (tags) {
			useTags = tags;
		}

		let useMedia = media;
		if (newMedia) {
			useMedia = newMedia;
		}

		if (useTags.length === 0) {
			let filtered = format.sortObjs(useMedia, 'name');
			setMediaFiltered([...filtered]);
			return;
		}

		let filtered = [];
		useMedia.forEach((mediaItem) => {
  
			let matchesCount = 0;
		  
			useTags.forEach((tag) => {
			  
				let isMatch = false;
			  
				mediaItem.tags.forEach((mediaTag) => {
				  if (mediaTag.value === tag) {
					isMatch = true;
				  }
				});
			  
				if (isMatch) {
				  matchesCount++;
				}
			  
			});
		  
			if (matchesCount === useTags.length) {
			  filtered.push(mediaItem);
			}
		  
		});

		filtered = format.sortObjs(filtered, 'name');

		setMediaFiltered([...filtered]);

	};

    // Toggle filters
    const toggleFilters = (shouldOpen) => {
        setFiltersIsOpen(shouldOpen);
    }

	// Return component
	return (

        <Flex
            width="1200px"
            height="860px"
            className="position-center-fixed z-index-1000 background-white shadow-6 radius-10"
            overflowX="hidden"
            overflowY="auto"
            direction="column"
            justify="flex-start"
            align="flex-start"
            p="40px"
        >

            <Flex
                width="100%"
                height="50px"
                justify="start"
                align="start"
                direction="column"
            >

                <Flex
                    width="100%"
                    height="40px"
                    justify="between"
                    align="start"
                >

                    <Heading>Select Media</Heading>

                    <IconButton
                        icon={<Cross1Icon />}
                        onClick={props.closeCallback}
                    ></IconButton>

                </Flex>

                <Separator 
                    size="3"
                    mt="10px"
                    className="background-primary"
                />

            </Flex>
            
            <Flex
                width="100%"
                minHeight="10px"
                overflowY="auto"
                overflowX="hidden"
                justify="start"
                align="start"
                direction="column"
                mt="10px"
            >

                <Flex
                    width="100%"
                    height="700px"
                    mt="20px"
                    overflowX="hidden"
                    overflowY="scroll"
                    justify="start"
                    align="start"
                    direction="row"
                    wrap="wrap"
                >

                    {isLoading &&
                        <Skeleton
                            width="100%"
                            height="250px"
                        >Loading</Skeleton>
                    }

                    {!isLoading &&

                        mediaFiltered.length == 0 ?

                            <Text>No media found.</Text>

                        :

                        mediaFiltered.map((mediaItem, index) => {

                            let width = "260px";
                            let height="200px";

                            let searchIndex = format.findIndex(selectedMedia, '_id', mediaItem._id);
                            let isSelected = false;
                            if (searchIndex >= 0) {
                                isSelected = true;
                            }

                            return (

                                <Flex
                                    key={index}
                                    width={width}
                                    height={height}
                                    className="background-very-light-grey radius-5"
                                    mr="20px"
                                    mb="20px"
                                    justify="center"
                                    align="center"
                                    position="relative"
                                    overflow="hidden"
                                >

                                    <Skeleton
                                        width={width}
                                        height={height}
                                    >Loading...</Skeleton>

                                    <Flex
                                        width={width}
                                        height={height}
                                        position="absolute"
                                        onClick={() => { handleMediaSelect(mediaItem); }}
                                        className="cursor-pointer"
                                    >

                                        {mediaItem.type.includes('image') &&
                                        
                                            <img
                                                src={assets.getURL('viking', `${mediaItem.folder}/${mediaItem.fileName}?w=520`)}
                                                alt=""
                                                className='img-full-square'
                                            />

                                        }

                                        {mediaItem.type.includes('video') &&

                                            <Flex
                                            justify="center"
                                            align="center"
                                            width={width}
                                            height={height}
                                            >
                                                <video 
                                                    width="100%" 
                                                    height="100%" 
                                                    controls
                                                    onClick={(e) => { e.preventDefault(); }}
                                                >
                                                    <source src={assets.getURL('viking', `${mediaItem.folder}/${mediaItem.fileName}`)} type={mediaItem.type} />
                                                </video>
                                            </Flex>
                                        }

                                        {mediaItem.type.includes('audio') &&
                                            <Flex
                                                justify="center"
                                                align="center"
                                                width={width}
                                                height={height}
                                            >
                                                <audio width="100%" height="100%" controls>
                                                    <source src={assets.getURL('viking', `${mediaItem.folder}/${mediaItem.fileName}`)} type={mediaItem.type} />
                                                </audio>
                                            </Flex>
                                        }

                                        {isSelected &&
                                        
                                            <Flex
                                            width={width}
                                            height={height}
                                                position="absolute"
                                                className="border-solid-primary-thick"
                                                justify="end"
                                                align="end"
                                            >

                                                <Flex
                                                    width="20px"
                                                    height="20px"
                                                    className="background-primary radius-full"
                                                    justify="center"
                                                    align="center"
                                                    mb="10px"
                                                    mr="10px"
                                                >
                                                    <CheckIcon
                                                        className="colour-white"
                                                    />
                                                </Flex>

                                            </Flex>

                                        }

                                    </Flex>

                                </Flex>

                            );

                        })

                    }

                </Flex>

                <Flex
                    width="100%"
                    height="50px"
                    mt="20px"
                    justify="between"
                    align="center"
                >

                    <IconButton
                        icon={<MixerHorizontalIcon />}
                        onClick={() => { toggleFilters(true); }}
                    >Filters</IconButton>

                    <IconButton
                        icon={<CheckIcon />}
                        onClick={() => { props.saveCallback(selectedMedia); }}
                    >Save</IconButton>

                </Flex>

            </Flex>

            {filtersIsOpen &&

                <Flex
                    className="background-very-light-grey shadow-6 radius-10"
                    width="350px"
                    height="500px"    
                    position="absolute"
                    top="255px"
                    justify="start"
                    align="start"
                    direction="column"    
                    overflow="hidden"    
                >

                    <Flex
                        width="100%"
                        height="30px"
                        mt="20px"
                        pl="20px"
                    >

                        {tagsTypes.map((tag, index) => (
                            <Badge
                                key={index}
                                size="2"
                                color={activeTags.includes(tag.value) ? 'orange' : 'gray'}
                                mr="10px"
                                className="cursor-pointer"
                                onClick={() => { handleTagClick(tag.value); }}
                            >{tag.label}</Badge>
                        ))}

                    </Flex>

                    <Flex
                        width="100%"
                        minHeight="50px"
                        overflowX="hidden"
                        overflowY="auto"
                        mt="20px"
                        pl="20px"
                        pr="20px"
                        wrap="wrap"
                    >

                        {tagsYears.map((tag, index) => (
                            <Badge
                                key={index}
                                size="2"
                                color={activeTags.includes(tag.value) ? 'orange' : 'gray'}
                                mr="10px"
                                mb="10px"
                                className="cursor-pointer"
                                onClick={() => { handleTagClick(tag.value); }}
                            >{tag.label}</Badge>
                        ))}

                    </Flex>

                    <Flex
                        width="100%"
                        minHeight="50px"
                        overflowX="hidden"
                        overflowY="auto"
                        wrap="wrap"
                        mt="20px"
                        pl="20px"
                        pr="20px"
                    >

                        {tags.map((tag, index) => (
                            <Badge
                                key={index}
                                size="2"
                                color={activeTags.includes(tag.value) ? 'orange' : 'gray'}
                                mr="10px"
                                mb="10px"
                                className="cursor-pointer"
                                onClick={() => { handleTagClick(tag.value); }}
                            >{tag.label}</Badge>
                        ))}

                    </Flex>

                    <Flex
                        width="20px"
                        height="20px"
                        position="absolute"
                        className="cursor-pointer background-dark-grey radius-3"
                        top="10px"
                        right="10px"
                        justify="center"
                        align="center"
                        onClick={ () => { toggleFilters(false); } }
                    >
                        <Cross1Icon
                            className="colour-white"
                        />
                    </Flex>

                </Flex>

            }

        </Flex>

    )

});

// Props
MediaPicker.propTypes = {
    closeCallback: PropTypes.func.isRequired,
    allowMultiple: PropTypes.bool,
    saveCallback: PropTypes.func.isRequired,
    initiallySelected: PropTypes.array,
    allowedTypes: PropTypes.string
};

// Export Component
export default MediaPicker;