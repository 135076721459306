// Imports
import React, { useState, useEffect } from 'react';

// Components
import Page from '../../../elements/Page';
import Section from '../../../elements/Section';
import { Skeleton, Flex, Text } from '@radix-ui/themes'
import MediaPicker from '../../../inputs/MediaPicker';
import IconButton from '../../../buttons/IconButton';
import Alert from '../../../feedback/Alert';

// Utils
import api from '@vikingevents/viking-fe-util-api';
import spinner from '@vikingevents/viking-fe-util-spinner';
import notification from '@vikingevents/viking-fe-util-notification';
import session from '@vikingevents/viking-fe-util-session';
import assets from '@vikingevents/viking-fe-util-assets';
import defaults from '../../../../utils/defaults';

// Icons
import { TrashIcon } from '@radix-ui/react-icons';

// ManageGalleryPage component
const ManageGalleryPage = () => {

	// State
	const [sessionData, setSessionData] = useState(session.get(defaults.consts.SESSION_KEY));
	const [gallery, setGallery] = useState([]);
	const [galleryIsLoading, setGalleryIsLoading] = useState(true);
	const [mediaPickerIsOpen, setMediaPickerIsOpen] = useState(false);

	// Get gallery 
	useEffect(() => { 

		const getGallery = async () => {

			spinner.show();

			await api.send({
                method: "GET",
                cacheKey: defaults.consts.CACHE_KEY,
                sessionKey: defaults.consts.SESSION_KEY,
				cacheableEndpoints: defaults.consts.CACHEABLE_ENDPOINTS,
                api: "viking_internal",
                endpoint: "/marketing/gallery",
                requiresAuth: true
            }).then((response) => {
                setGallery(response.data.gallery);
				setGalleryIsLoading(false);
				spinner.hide();
            }).catch((error) => {
				spinner.hide();
            });


		};

		getGallery();

	}, []);

	// Toggle add media 
	const toggleAddMedia = (shouldOpen) => {
		setMediaPickerIsOpen(shouldOpen);
	};

	// Add media submit
	const addMediaSubmit = (media) => {

		if (media.length === 0) {
			notification.error("No media selected.");
			return;
		}

		spinner.show();

		api.send({
			method: "POST",
			cacheKey: defaults.consts.CACHE_KEY,
			sessionKey: defaults.consts.SESSION_KEY,
			api: "viking_internal",
			endpoint: "/marketing/gallery/update",
			requiresAuth: true,
			data: {
				media: media
			}
		}).then((response) => {
			notification.success("Media added to gallery.");
			setGallery(response.data.gallery);
			toggleAddMedia(false);
			spinner.hide();
		}).catch((error) => {
			toggleAddMedia(false);
			spinner.hide();
		});

	};

	// Delete media
	const deleteMedia = (media) => {
		
		spinner.show();

		api.send({
			method: "POST",
			cacheKey: defaults.consts.CACHE_KEY,
			sessionKey: defaults.consts.SESSION_KEY,
			api: "viking_internal",
			endpoint: "/marketing/gallery/delete",
			requiresAuth: true,
			data: {
				media: media
			}
		}).then((response) => {
			notification.success("Media deleted from gallery.");
			setGallery(response.data.gallery);
			spinner.hide();
		}).catch((error) => {
			spinner.hide();
		});

	};

	// Return component
	return (

		<Page 
			title="Marketing - Manage Gallery"
		>

			<Section
				title="Marketing - Manage Gallery"
				addText="Add Media"
				onAddClick={sessionData.user.permissions.includes("MarketingGalleryFullAccess") ? () => { toggleAddMedia(true); } : null}
			>

				{galleryIsLoading &&
					<Skeleton
						width="100%"
						height="250px"
					>Loading</Skeleton>
				}

				{!galleryIsLoading &&
					
					<Flex
						width="100%"
						minHeight="200px"
						overflowX="hidden"
						overflowY="auto"
						justify="start"
						align="start"
						wrap="wrap"
						mb="40px"
					>

						{gallery.length === 0 &&
							<Text>No media has been added to the gallery yet.</Text>
						}

						{gallery.length > 0 &&

							gallery.map((media, index) => {

								return (

									<Flex
										width="200px"
										height="200px"
										className="background-very-light-grey"
										mr="20px"
										mt="20px"
										key={index}
										justify="center"
										align="center"
										position="relative"
										overflow="hidden"
									>

										<img
											src={assets.getURL('viking', `${media.file}?w=500`)}
											alt={media.name}
											className="img-full-square"
										/>

										<Flex
											position="absolute"
											bottom="10px"
											right="10px"
										>

											<Alert
												title="Delete Media"
												message="This will delete this media from the gallery."
												confirmText="Delete"
												confirmAction={ () => { deleteMedia(media); }}
											>

												<Flex
													width="24px"
													height="24px"
													overflow="hidden"
													align="center"
													justify="center"
													className="cursor-pointer background-danger-hover radius-3"
												>
													<TrashIcon
														className="colour-white"
														width="14px"
														height="14px"
													/>
												</Flex>

											</Alert>

										</Flex>

									</Flex>

								);

							})

						}

					</Flex>

				}

			</Section>

			{mediaPickerIsOpen &&
				<MediaPicker 
					closeCallback={() => { toggleAddMedia(false); }}
					allowMultiple={true}
					saveCallback={addMediaSubmit}
					allowedTypes={"image"}
				/>
			}

		</Page>

    )

};

// Export Component
export default ManageGalleryPage;
