// Imports
import React from 'react';
import PropTypes from 'prop-types';
import { Select } from '@radix-ui/themes'

// Utils
//import Format from '../../../utils/format';
import format from '@vikingevents/viking-fe-util-format'

// NumberInput component
const NumberInput = React.forwardRef((props, ref) => {

    // Handle on change
    const handleChange = (value) => {
        props.onChange(value);
    }

    // Sort options
    let options = props.options;
    if (options[0].value === "select") {
        options.splice(0, 1);
    }
    let otherIndex = format.findIndex(options, "value", "other");
    if (otherIndex > -1) {
        options.splice(otherIndex, 1);
    }
    let sortedOptions = [];
    if (Number(options[0].value) !== "NaN") {
        sortedOptions = sortedOptions.sort((a, b) => {
            return Number(a.value) - Number(b.value);
        });
    } else {
        sortedOptions = format.sortObjs(options, "label");
    }
    if (!props.noSelect) {
        sortedOptions.unshift({value: "select", label: "Select..."});
    }
    if (otherIndex > -1) {
        sortedOptions.push({
            value: "other",
            label: "Other"
        });
    }

    // Handle value change
    const handleValueChange = (value) => {

        if (props.onChangeParams) {
            props.onChange(value, props.onChangeParams);
        } else {
            props.onChange(value, null);
        }

    }

	// Return component
	return (

        <Select.Root 
            defaultValue={props.defaultValue}
            disabled={props.disabled}
            onValueChange={(value) => handleValueChange(value)}
            color="orange"
        >

            <Select.Trigger 
                mt="10px"
                mb="20px"
                color="orange"
            />

            <Select.Content
                color="orange"
            >

                <Select.Group
                    color="orange"
                >

                    {options.map((option, index) => (

                        <Select.Item 
                            value={option.value}
                            key={index}
                            color="orange"
                        >{option.label}</Select.Item>
                        
                    ))}

                </Select.Group>

            </Select.Content>

        </Select.Root>

    )

});

// Props
NumberInput.propTypes = {
    disabled: PropTypes.bool,
    defaultValue: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    noSelect: PropTypes.bool,
    onChangeParams: PropTypes.array
};

// Export Component
export default NumberInput;
