// Imports
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// Components
import { Flex, Heading, Separator} from '@radix-ui/themes';
import IconButton from '../../buttons/IconButton';
import ContentViewer from '../../elements/ContentViewer';
import MediaPicker from '../../inputs/MediaPicker';
import Alert from '../../feedback/Alert';

// Icons
import { Cross1Icon, CheckIcon, FileTextIcon, PlusIcon, FileIcon, TrashIcon } from '@radix-ui/react-icons';

// Utils
import notification from '@vikingevents/viking-fe-util-notification';

// ContentInput component
const ContentInput = React.forwardRef((props, ref) => {

    // Quill
    const quillRef = useRef(null);
    const modules = {
        toolbar: [
          [{ 'header': [1, 2, false] }],
          ['bold', 'italic', 'underline','strike', 'blockquote'],
          [{'list': 'ordered'}, {'list': 'bullet'}],
          ['link'],
          ['clean']
        ]
    };

    useEffect(() => {
        if (quillRef.current) {
            const quill = quillRef.current.getEditor();

            // Intercept pasted content and process it
            quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
            // Extract plain text content
            const text = node.innerText || node.textContent || '';
            return new quill.constructor.imports.delta([{ insert: text }]);
            });

            // Optional: Ensure cursor is positioned correctly after paste
            quill.on('text-change', () => {
            const range = quill.getSelection();
            if (range) {
                quill.setSelection(range.index, 0);
            }
            });
        }
    }, []);


    // State 
    const [value, setValue] = useState(props.content ? convertToHTML(props.content) : "");
    const [media, setMedia] = useState(props.media || []);
    const [previewContent, setPreviewContent] = useState([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [mediaPickerVisible, setMediaPickerVisible] = useState(false);

    // Handle preview
    const handlePreview = () => {
        console.log(value);
        setPreviewContent(parseHTMLToArray(value));
        togglePreview(true);
    };

    const parseHTMLToArray = (html) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const elementsArray = [];

        // Recursive function to process elements
        const processElement = (element) => {
            const obj = {
                tag: element.tagName.toLowerCase(),
                attributes: {},
                content: [],
            };

            // Extract attributes
            for (const attr of element.attributes) {
                obj.attributes[attr.name] = attr.value;
            }

            // Extract child content
            for (const child of element.childNodes) {
                
                if (child.nodeType === Node.TEXT_NODE) {
                    // Add text content
                    const trimmedText = child.textContent.trim();
                    if (trimmedText) obj.content.push(trimmedText);
                    
                } else if (child.nodeType === Node.ELEMENT_NODE) {
                    
                    if (child.tagName.toLowerCase() !== "br") {
                        // Recursively process child elements
                    obj.content.push(processElement(child));
                    }
                }
            }

            return obj;
        };

        // Process top-level child elements of <body>
        for (const child of doc.body.children) {
            elementsArray.push(processElement(child));
        }
        
        // Remove empty elements
        let nonEmptyEelements = [];
        elementsArray.forEach((element) => {
            if (element.content.length > 0) {
            nonEmptyEelements.push(element);
            }
        });

        return nonEmptyEelements;
    };

    function convertToHTML(content) {
        // Recursive function to process each element
        const processElement = (element) => {
          // Extract tag, attributes, and content
          const { tag, attributes, content } = element;
      
          // Convert attributes object to string
          const attributesString = attributes
            ? Object.entries(attributes)
                .map(([key, value]) => `${key}="${value}"`)
                .join(' ')
            : '';
      
          // Generate opening tag with attributes
          const openingTag = attributesString ? `<${tag} ${attributesString}>` : `<${tag}>`;
      
          // Process nested content or plain text
          const innerContent = Array.isArray(content)
            ? content.map((child) => (typeof child === 'string' ? child : processElement(child))).join('')
            : '';
      
          // Generate closing tag
          const closingTag = `</${tag}>`;
      
          // Return the full HTML for the element
          return `${openingTag}${innerContent}${closingTag}`;
        };
      
        // Process the top-level array
        return content.map(processElement).join('');
    }

    // Toggle preview
    const togglePreview = (shouldOpen) => {
        setPreviewVisible(shouldOpen);
    }

    // Toggle media picker
    const toggleMediaPicker = (shouldOpen) => {
        setMediaPickerVisible(shouldOpen);
    }

    // Handle media picker save
    const handleMediaPickerSave = (media) => {
        setMedia(media);
        toggleMediaPicker(false);
    }

    // Handle save
    const handleSave = (isDraft) => {

        if (props.mediaRequired && media.length === 0) {
            notification.error("Please add at least one media item");
            return;
        }
        
        // Data
        let content = parseHTMLToArray(value);
        let mediaItems = media;

        if (props.isEdit) {
            props.editContentSaveCallback(content, mediaItems, isDraft, props.article);
        } else {
            props.addContentSaveCallback(content, mediaItems, isDraft);
        }

    };

    // Return component
    return (

        <Flex
            width="1200px"
            height="860px"
            className="position-center-fixed z-index-90 background-white shadow-6 radius-10"
            overflowX="hidden"
            overflowY="auto"
            direction="column"
            justify="flex-start"
            align="flex-start"
            p="40px"
        >

            <Flex
                width="100%"
                height="50px"
                justify="start"
                align="start"
                direction="column"
            >

                <Flex
                    width="100%"
                    height="40px"
                    justify="between"
                    align="start"
                >

                    <Heading>Content Input</Heading>

                    <IconButton
                        icon={<Cross1Icon />}
                        onClick={() => { props.closeCallback(false); }}
                    ></IconButton>

                </Flex>

                <Separator 
                    size="3"
                    mt="10px"
                    className="background-primary"
                />

            </Flex>
            
            <Flex
                width="100%"
                minHeight="10px"
                overflowY="auto"
                overflowX="hidden"
                justify="start"
                align="start"
                direction="column"
                mt="10px"
            >

                <Flex
                    width="100%"
                    height="700px"
                    mt="20px"
                    overflow="hidden"
                    justify="start"
                    align="start"
                    direction="column"
                >

                    <ReactQuill 
                        ref={quillRef}
                        theme="snow" 
                        value={value} 
                        onChange={setValue} 

                        placeholder="Write a news article..."
                        modules={modules}
                        style={{
                            width: "100%",
                            height: "590px"
                        }}
                        id="editor"
                        bounds={'#editor'}
                    />

                </Flex>

                <Flex
                    width="100%"
                    height="50px"
                    mt="20px"
                    justify="between"
                    align="center"
                >

                    <Flex
                        width="230px"
                        justify="between"
                        align="center"
                    >

                        <IconButton
                            icon={<PlusIcon />}
                            onClick={() => { toggleMediaPicker(true); }}
                        >Add Media</IconButton>

                        <IconButton
                            icon={<FileTextIcon />}
                            onClick={() => { handlePreview(); }}
                        >Preview</IconButton>

                    </Flex>

                    <Flex
                        width={props.isEdit ? "260px" : "220px"}
                        justify="between"
                        align="center"
                    >

                        {props.isEdit &&
                            <Alert
                                title="Delete Content"
                                message="This will delete the content from the website."
                                confirmText="Delete"
                                confirmAction={ () => { props.deleteContentCallback(props.article); }}
                            >

                                <Flex
                                    width="32px"
                                    height="32px"
                                    overflow="hidden"
                                    align="center"
                                    justify="center"
                                    className="cursor-pointer background-danger-hover radius-3"
                                >
                                    <TrashIcon
                                        className="colour-white"
                                        width="14px"
                                        height="14px"
                                    />
                                </Flex>

                            </Alert>
                        }

                        <IconButton
                            icon={<FileIcon />}
                            onClick={() => { handleSave(true); }}
                            isSecondary={true}
                        >Save Draft</IconButton>

                        <IconButton
                            icon={<CheckIcon />}
                            onClick={() => { handleSave(false); }}
                        >Publish</IconButton>

                    </Flex>

                </Flex>

            </Flex>

            {previewVisible &&

                <Flex
                    width="100%"
                    height="100vh"
                    className="z-index-1000 background-dark-grey-trans"
                    position="fixed"
                    margin="auto"
                    top="0"
                    left="0"
                    right="0"
                    bottom="0"
                    justify="center"
                    align="center"
                >

                    <Flex
                        width="880px"
                        height="800px"
                        overflow="hidden"
                    >

                        <ContentViewer 
                            content={previewContent}
                            media={media}
                        />

                    </Flex>

                    <Flex
                        width="32px"
                        height="32px"
                        position="absolute"
                        className="background-primary radius-3 cursor-pointer"
                        margin="auto"
                        top="20px"
                        right="20px"
                        justify="center"
                        align="center"
                        onClick={() => { togglePreview(false); }}
                    >
                        <Cross1Icon 
                            className="colour-white"
                        />
                        
                    </Flex>

                </Flex>

            }

            {mediaPickerVisible &&
            
                <MediaPicker
                    closeCallback={() => { toggleMediaPicker(false); }}
                    saveCallback={handleMediaPickerSave}
                    allowMultiple={true}
                    initiallySelected={media}
                    />

                }

            </Flex>

    )

});

// Props
ContentInput.propTypes = {
    closeCallback: PropTypes.func.isRequired,
    article: PropTypes.object,
    content: PropTypes.array,
    media: PropTypes.array,
    isEdit: PropTypes.bool,
    addContentSaveCallback: PropTypes.func,
    editContentSaveCallback: PropTypes.func,
    deleteContentCallback: PropTypes.func,
    mediaRequired: PropTypes.bool,
};

// Export Component
export default ContentInput;
