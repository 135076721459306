// Imports
import React, { useEffect, useState, useRef } from 'react';

// Components
import Page from '../../../elements/Page';
import Section from '../../../elements/Section';

// Sections
import AddUsers from '../../../sections/manageUsersPage/AddUsers';
import EditUsers from '../../../sections/manageUsersPage/EditUsers';
import ViewPermissions from '../../../sections/manageUsersPage/ViewPermissions';
import ViewUsers from '../../../sections/manageUsersPage/ViewUsers';

// Utils
import defaults from '../../../../utils/defaults';
import api from '@vikingevents/viking-fe-util-api';
import notification from '@vikingevents/viking-fe-util-notification';
import spinner from '@vikingevents/viking-fe-util-spinner';
import session from '@vikingevents/viking-fe-util-session';

// ManageUsersPage component
const ManageUsersPage = () => {

	// State
	const [loadingUsers, setLoadingUsers] = useState(true);
	const [users, setUsers] = useState([]);
	const [usersStatic, setUsersStatic] = useState([]);
	const [selectedUser, setSelectedUser] = useState(null);
	const [permissions, setPermissions] = useState([]);

	const [sessionData, setSessionData] = useState(session.get(defaults.consts.SESSION_KEY));

	const [addUserIsVisible, setAddUserIsVisible] = useState(false);
	const [viewPermissionsIsVisible, setViewPermissionsIsVisible] = useState(false);
	const [editUserIsVisible, setEditUserIsVisible] = useState(false);
	
	// Get users
	useEffect(() => { 

		const getUsers = async () => {

			spinner.show();

			api.send({
                method: "GET",
                cacheKey: defaults.consts.CACHE_KEY,
                sessionKey: defaults.consts.SESSION_KEY,
				cacheableEndpoints: defaults.consts.CACHEABLE_ENDPOINTS,
                api: "viking_internal",
                endpoint: "/users",
				requiresAuth: true
            }).then((response) => {

                spinner.hide();
				notification.success("Users loaded successfully.");

				setUsers(response.data.users);
				setUsersStatic(response.data.users);
				setLoadingUsers(false);

				getPermissions();

            }).catch((error) => {
                
                spinner.hide();

            });
		}

		const getPermissions = async () => {

			api.send({
                method: "GET",
                cacheKey: defaults.consts.CACHE_KEY,
                sessionKey: defaults.consts.SESSION_KEY,
                api: "viking_internal",
                endpoint: "/permissions",
				requiresAuth: true
            }).then((response) => {

                setPermissions(response.data.permissions);
				setPermissionsStatic(response.data.permissions);

            }).catch((error) => {});

		}

		getUsers();

	}, []);

	// Show add user
	const toggleAddUser = (shouldOpen) => {
		if (permissions.length !== 0) {
			setAddUserIsVisible(shouldOpen);
		} else {
			spinner.show();
			setTimeout(() => {
				setAddUserIsVisible(shouldOpen);
				spinner.hide();
			}, 500);
		}
	};

	// Show user permissions
	const toggleViewPermissions = (shouldOpen, user = null) => {
		if (permissions.length !== 0) {
			setSelectedUser(user);
			setViewPermissionsIsVisible(shouldOpen);
		} else {
			spinner.show();
			setTimeout(() => {
				setSelectedUser(user);
				setViewPermissionsIsVisible(shouldOpen);
				spinner.hide();
			}, 500);
		}
	};

	// Show edit user
	const toggleEditUser = (shouldOpen, user = null) => {
		setEditUserIsVisible(shouldOpen);
		setSelectedUser(user);
	}

	// Render
	return (

		<Page 
			title="Settings - Manage Users"
		>

			<Section
				title="Settings - Manage Users"
				addText="Add User"
				onAddClick={sessionData.user.permissions.includes("UsersFullAccess") ? () => { toggleAddUser(true); } : null}
			>

				<ViewUsers
					loadingUsers={loadingUsers}
					users={users}
					usersStatic={usersStatic}
					setUsers={setUsers}
					toggleViewPermissions={toggleViewPermissions}
					toggleEditUser={toggleEditUser}
				/>

				{addUserIsVisible &&
					<AddUsers 
						toggleAddUser={toggleAddUser}
						setUsers={setUsers}
						setUsersStatic={setUsersStatic}
						permissions={permissions}
					/>
				}

				{viewPermissionsIsVisible &&
					<ViewPermissions
						toggleViewPermissions={toggleViewPermissions}
						selectedUser={selectedUser}
						users={users}
						usersStatic={usersStatic}
						setSelectedUser={setSelectedUser}
						setUsers={setUsers}
						setUsersStatic={setUsersStatic}
						permissions={permissions}
					/>
				}

				{editUserIsVisible &&
					<EditUsers
						toggleEditUser={toggleEditUser}
						users={users}
						usersStatic={usersStatic}
						setUsers={setUsers}
						setUsersStatic={setUsersStatic}
						selectedUser={selectedUser}
						setSelectedUser={setSelectedUser}
					/>
				}

			</Section>

		</Page>

	);

};

// Export Component
export default ManageUsersPage;
