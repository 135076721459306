// Imports
import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@radix-ui/themes'

// TextInput component
const TextInput = React.forwardRef((props, ref) => {

	// Return component
	return (

        <TextField.Root 
            placeholder={props.placeholder}
            type={props.type}
            ref={ref}
            mt="10px"
            mb="20px"
            fontSize="14px"
            disabled={props.disabled}
            color="orange"
            onChange={props.onChangeCallback ? () => { props.onChangeCallback(); } : null}
        >
        </TextField.Root>

    )

});

// Props
TextInput.propTypes = {
    placeholder: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    onChangeCallback: PropTypes.func,
};

// Export Component
export default TextInput;
