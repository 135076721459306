// Imports
import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';

// Components
import Header from '../navigation/Header';
import { Theme, Flex, Text } from '@radix-ui/themes';
import Spinner from '../feedback/Spinner';
import SideNav from '../navigation/SideNav';

// Pages
import HomePage from '../pages/HomePage';
import LoginPage from '../pages/LoginPage';
import PageNotFoundPage from '../pages/PageNotFoundPage';
import AccountPage from '../pages/AccountPage';
import ClientsViewPage from '../pages/clients/ClientsViewPage';
import ClientsManagePage from '../pages/clients/ClientsManagePage';
import ProjectsPage from '../pages/work/ProjectsPage';
import HiresPage from '../pages/work/HiresPage';
import QuotesPage from '../pages/work/QuotesPage';
import InvoicesPage from '../pages/work/InvoicesPage';
import StockPage from '../pages/assets/StockPage';
import TrailersPage from '../pages/assets/TrailersPage';
import ContainersPage from '../pages/assets/ContainersPage';
import VehiclesPage from '../pages/assets/VehiclesPage';
import EquipmentPage from '../pages/assets/EquipmentPage';
import MaintenancePage from '../pages/assets/MaintenancePage';
import RequestConsumablesPage from '../pages/assets/RequestConsumablesPage';
import ReportIssuePage from '../pages/assets/ReportIssuePage';
import ManageGalleryPage from '../pages/marketing/ManageGalleryPage';
import ManageNewsPage from '../pages/marketing/ManageNewsPage';
import ManageRecentProjectsPage from '../pages/marketing/ManageRecentProjectsPage';
import ManageTeamPage from '../pages/marketing/ManageTeamPage';
import ManageUsersPage from '../pages/settings/ManageUsersPage';
import ManagePermissionsPage from '../pages/settings/ManagePermissionsPage';
import ManageMediaPage from '../pages/marketing/ManageMediaPage';
import UserActionsPage from '../pages/settings/UserActionsPage';
import SocialAnalyticsPage from '../pages/marketing/SocialAnalyticsPage';
import SocialSchedulerPage from '../pages/marketing/SocialSchedulerPage';

// Utils
import defaults from '../../utils/defaults';
import session from '@vikingevents/viking-fe-util-session';

// Assets
import VikingLogo from '../../assets/logos/logo-trans-orange.png';

// Container component
const Container = (props) => {

    // State
    const [checkingSession, setCheckingSession] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [sessionData, setSessionData] = useState(null);

    // Check session
    useEffect(() => {

      const checkSession = async () => {
            
          let result = await session.check(defaults.consts.SESSION_KEY, 'viking_internal');
          
          if (!result) {

            session.remove(defaults.consts.SESSION_KEY);
            setCheckingSession(false);
            setIsLoggedIn(false);

          } else {

            // Get session
            let sessionData = session.get(defaults.consts.SESSION_KEY);
            if (sessionData.user.requiresNewPassword) {

              session.remove(defaults.consts.SESSION_KEY);
              setCheckingSession(false);
              setIsLoggedIn(false);

            } else {
            
              setCheckingSession(false);
              setSessionData(sessionData);
              setIsLoggedIn(true);

            }

          }

      }

      checkSession();

    }, []);

    if (checkingSession) {

      return (

        <Theme accentColor="orange">

          <Flex
            justify="center"
            align="center"
            width="100%"
            height="100vh"
          >

            <Flex
              direction="column"
              align="center"
              justify="center"
            >

              <img 
                src={VikingLogo} 
                alt="ODIN" 
                width="150px" 
              />

              <Text
                mt="20px"
              >Checking session...</Text>

            </Flex>

          </Flex>
          
        </Theme>

      );
    }

    if (!checkingSession && isLoggedIn) {

        return (

            <Theme>
    
                <Header />

                <Flex
                    width="100%"
                    minHeight="300px"
                    overflowX="hidden"
                    overflowY="auto"
                    pt="100px"
                >
                
                    <SideNav />

                    <Flex
                        width="1020px"
                        minHeight="300px"
                        overflowX="hidden"
                        overflowY="auto"
                    >

                    <Routes>
                            
                        <Route path="/" element={<HomePage />} />
                        <Route path="/account" element={<AccountPage />} />

                        <Route path="/clients" element={<ClientsViewPage />} />
                        <Route path="/clients/manage" element={<ClientsManagePage />} />
                        <Route path="/projects" element={<ProjectsPage />} />
                        <Route path="/hires" element={<HiresPage />} />
                        <Route path="/quotes" element={<QuotesPage />} />
                        <Route path="/invoices" element={<InvoicesPage />} />

                        <Route path="/assets/stock" element={<StockPage />} />
                        <Route path="/assets/trailers" element={<TrailersPage />} />
                        <Route path="/assets/containers" element={<ContainersPage />} />
                        <Route path="/assets/vehicles" element={<VehiclesPage />} />
                        <Route path="/assets/equipment" element={<EquipmentPage />} />
                        <Route path="/assets/maintenance" element={<MaintenancePage />} />
                        <Route path="/assets/consumables" element={<RequestConsumablesPage />} />
                        <Route path="/assets/report-issue" element={<ReportIssuePage />} />

                        <Route path="/marketing/gallery" element={<ManageGalleryPage />} />


                        {(sessionData.user.permissions.includes('MarketingNewsFullAccess')) &&

                          <Route path="/marketing/news" element={<ManageNewsPage />} />

                        }


                        {(sessionData.user.permissions.includes('MarketingRecentProjectsFullAccess')) &&

                          <Route path="/marketing/recent-projects" element={<ManageRecentProjectsPage />} />

                        }

                        {(sessionData.user.permissions.includes('MarketingTeamFullAccess')) &&

                          <Route path="/marketing/team" element={<ManageTeamPage />} />

                        }


                        {(sessionData.user.permissions.includes('MarketingMediaFullAccess') || sessionData.user.permissions.includes('MarketingMediaReadOnly')) &&

                          <Route path="/marketing/media" element={<ManageMediaPage />} />

                        }

                        {(sessionData.user.permissions.includes('MarketingSocialAnalyticsFullAccess')) &&

                        <Route path="/marketing/social/analytics" element={<SocialAnalyticsPage />} />

                        }

                        {(sessionData.user.permissions.includes('MarketingSocialScheduleFullAccess') || sessionData.user.permissions.includes('MarketingSocialScheduleReadOnly')) &&

                        <Route path="/marketing/social/schedule" element={<SocialSchedulerPage />} />

                        }


                        {(sessionData.user.permissions.includes('UsersFullAccess') || sessionData.user.permissions.includes('UsersReadOnly')) &&

                          <Route path="/settings/users" element={<ManageUsersPage />} />

                        }

                        {(sessionData.user.permissions.includes('PermissionsFullAccess') || sessionData.user.permissions.includes('PermissionsReadOnly')) &&

                           <Route path="/settings/permissions" element={<ManagePermissionsPage />} />

                        }

                        {(sessionData.user.permissions.includes('UserActionsFullAccess')) &&

                        <Route path="/settings/actions" element={<UserActionsPage />} />

                        }

                        <Route path="*" element={<PageNotFoundPage />} />

                    </Routes>

                    </Flex>

                </Flex>
            
                <Spinner />
    
            </Theme>
    
        );

    }

    if (!checkingSession && !isLoggedIn) {

        return (

            <Theme accentColor='orange'>

                <LoginPage />

                <Spinner />

            </Theme>

        );

    }

    
};

export default Container;


/*



*/