// Imports
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@radix-ui/themes'

// PrimaryButton component
const PrimaryButton = (props) => {

	// Return component
	return (

        <Button
            color="orange"
            onClick={ () => { props.onClick() }}
            loading={props.isLoading}
            variant={props.variant || 'solid'}
            disabled={props.isDisabled}
        >{props.children}</Button>

    )

};

// Props
PrimaryButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    children: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
    variant: PropTypes.string,
    isDisabled: PropTypes.bool
};

// Export Component
export default PrimaryButton;
